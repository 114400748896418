import React from 'react';
import './BadgeComponent.css';

interface MyComponentProps {
	text?: string;
	className?: string;
	noCap?: boolean;
	children?: any;
	onClick?: () => void;
}

const BadgeComponent: React.FC<MyComponentProps> = ({
	text,
	className,
	children,
	onClick,
	noCap
}) => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				onClick && onClick();
			}}
			className={`badge-reusable-wrap-index`}
		>
			<div className={`badge-wrap-index ${className} grey-bg-two`}>
				{children || ''} <span className={`${noCap && 'no-capitalize'}`}>{text || ''}</span>
			</div>
		</div>
	);
};

export default BadgeComponent;
