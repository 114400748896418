import React, { useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import SingleViewBankbox from './component/SingleViewBankbox';
import AllBankBox from './component/AllBankbox';
import {
	getActionNamesByPolicyName,
	mapHotkey,
} from '../../../../utils/helper/Helper';
import ExportModal from '../../../../components/common/exportModal';
import { useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import EmptyScreen from '../../../../components/common/emptyScreen';
import ManageInactiveModal from './component/ManagenactiveModal';

function BankboxTerminals() {
	const [showExport, setShowExport] = useState<boolean>(false);

	const [showModalInactive, setShowModalInactive] = useState<boolean>(false);
	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'terminals'
		)?.includes('view_terminals')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout>
				<AllBankBox setShowModalInactive={setShowModalInactive} onExport={() => setShowExport(true)} />
			</DashboardLayout>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="bankbox_terminals"
				visible={showExport}
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, '_blank')?.focus();
				}}
			/>
			<ManageInactiveModal
				visible={showModalInactive }
				onClose={() => {
					setShowModalInactive(false);
				}}
			/>
		</>
	);
}

export default BankboxTerminals;
