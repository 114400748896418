
import { RavenModal } from "@ravenpay/raven-bank-ui"
import "./airtimeBillsModalIndex.css"
type ButtonColors =
  | "purple-light"
  | "error-light"
  | "info-right"
  | "white-light"
  | "blue-light"
  | "green-light"
  | "orange-light"
  | "black-light"
  | "deep-green"
  | "deep-green-light"
  | "error-dark"
  | "deep-green-dark";
  // |"#F7F7F7";

interface BillsModalProps  {
direction?: string;
amount?: number;
businessEmail?: string;
type?: string;
fee?: number;
mobileNumber?: string;
provider?: string;
references?: string
merchantRef?: string;
status?: string;
date?: string;
plan?: string;
token?: string;
meterNumber?: string;
smartCard?: string;
userId?: string;
title?: string;
visible?: boolean;
onClose?: () => void;
btnLabel?: string;
btnColor?: ButtonColors;
singleChildren?:any;
}



const AirtimeBillModal: React.FC<BillsModalProps> = ({
direction,
amount,
businessEmail,
type,
fee,
mobileNumber,
provider,
references,
merchantRef,
status,
date,
plan,
token,
meterNumber,
smartCard,
userId,
title,
visible,
onClose,
btnColor,
btnLabel
// details,
}
) => {


  const renderContentRow = (label: string, value?: string | number) => {
    if (!value) return null; // Skip rendering if value is not provided
    return (
      <div className="bills_details_modal_contents_content_holder">
        <p className="bills_details_modal_contents_content_head">{label}</p>
        <p className="bills_details_modal_contents_content_details">{value}</p>
      </div>
    );
  };
  return(
  <RavenModal 
  onBtnClick={() => {}}
  visble={visible}
  onClose={onClose}
  btnColor={btnColor}
  btnLabel={btnLabel}
>
    <div className="bills_modal_contents_content_holder_wrap">
      <p className="bills_details_modal_header">{title}</p>
      <div className="bills_details_modal_contents_content_holder_wrap">

      {renderContentRow('Direction', direction)}
        {renderContentRow('Amount', amount)}
        {renderContentRow('Business Email', businessEmail)}
        {renderContentRow('Type', type)}
        {renderContentRow('Fee', fee)}
        {renderContentRow('Mobile No', mobileNumber)}
        {renderContentRow('Provider', provider)}
        {renderContentRow('Reference', references)}
        {renderContentRow('Merchant Ref', merchantRef)}
        {renderContentRow('Status', status)}
        {renderContentRow('Date', date)}
        {renderContentRow('Plan', plan)}
        {renderContentRow('Token', token)}
        {renderContentRow('Meter Number', meterNumber)}
        {renderContentRow('Smart Card', smartCard)}
        {renderContentRow('User ID', userId)}

      </div>
    </div>
  </RavenModal>
  
)
}
export default AirtimeBillModal