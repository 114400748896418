import React, { useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import './styles/index.css';
import { icons, illustrations } from '../../../assets/icons';
import keyBird from '../../../assets/images/key_bird.png';
import privacyBird from '../../../assets/images/privacy_bird.png';
import { useNavigate } from 'react-router-dom';
import RootState from '../../../redux/types';
import { useSelector } from 'react-redux';
import { logger } from '../../../services/logger';
import MobileOnboardingSteps from './components/MobileOnboardingSteps';
function Onboarding() {
	const navigate = useNavigate();

	type Status = {
		1: boolean;
		2: boolean;
		3: boolean;
		4: boolean;
		5: boolean;
		[key: string]: any;
	};
	const [verified, setVerified] = useState<Status>({
		1: true,
		2: true,
		3: false,
		4: false,
		5: false,
	});

	function isVerified(num: number) {
		return verified[num];
	}

	const { profile } = useSelector((state: RootState) => state.wallet);

	const { businessInfo, merchantInfo } = profile;

	// logger.log('onboarding verified', profile);
	return (
		<DashboardLayout>
			<div className="onboarding">
				<div className="onboarding__hi-there">
					<h5>Hi there {profile?.fname} 👋🏻</h5>
					<p>
						To fully unlock the potential of the Raven Atlas we kindly complete
						your KYC.
					</p>
				</div>
				<div
					style={{ display: 'none' }}
					className="onboarding__verification-steps "
				>
					<div className="verification-steps__item">
						<div className="item__step-rail">
							<figure>
								{merchantInfo?.bvn === 3
									? icons.info_checked
									: icons.info_orange}
							</figure>
							<p>
								{merchantInfo?.bvn === 3
									? icons.dotted_line_inactive
									: icons.dotted_line}
							</p>
						</div>
						<div
							onClick={() => {
								navigate('/verification?kyc-stage=bvn');
							}}
							className="item__content"
						>
							<h5>BVN Verification</h5>
							<p>
								Swiftly validate your BVN, usually takes less than 1 minute.
							</p>
							<span className={`${merchantInfo?.bvn === 3 && 'verified'}`}>
								<p>{merchantInfo?.bvn === 3 ? 'Verified' : 'Pending'}</p>
							</span>
						</div>
					</div>

					<div className="verification-steps__item">
						<div className="item__step-rail">
							<figure>
								{businessInfo?.document_verification_status === 1
									? icons.info_checked
									: icons.info_orange}
							</figure>
							<p>
								{businessInfo?.document_verification_status === 1
									? icons.dotted_line_inactive
									: icons.dotted_line}
							</p>
						</div>
						<div
							onClick={() => {
								navigate('/verification?kyc-stage=id');
							}}
							className="item__content"
						>
							<h5>ID/NIN Verification</h5>
							<p>All your bill payment handled seamlessly</p>
							<span
								className={`${
									businessInfo?.document_verification_status === 1 && 'verified'
								}`}
							>
								<p>
									{businessInfo?.document_verification_status === 1
										? 'Verified'
										: 'Pending'}
								</p>
							</span>
						</div>
					</div>

					<div className="verification-steps__item">
						<div className="item__step-rail">
							<figure>
								{businessInfo?.cac_verified === 1
									? icons.info_checked
									: icons.info_orange}
							</figure>
							<p>
								{businessInfo?.cac_verified === 1
									? icons.dotted_line_inactive
									: icons.dotted_line}
							</p>
						</div>
						<div
							onClick={() => {
								navigate('/verification?kyc-stage=cac');
							}}
							className="item__content"
						>
							<h5>CAC Verification</h5>
							<p>All your bill payment handled seamlessly</p>
							<span
								className={businessInfo?.cac_verified === 1 ? 'verified' : ''}
							>
								<p>
									{businessInfo?.registration_number?.length > 1
										? 'Ongoing Review'
										: businessInfo?.cac_verified === 1
										? 'Verified'
										: 'Pending'}
								</p>
							</span>
						</div>
					</div>

					<div className="verification-steps__item">
						<div className="item__step-rail">
							<figure>
								{businessInfo?.industry?.length > 1 &&
								businessInfo?.transaction_range?.length > 1
									? icons.info_checked
									: icons.info_orange}
							</figure>
							<p>
								{businessInfo?.industry?.length > 1 &&
								businessInfo?.transaction_range?.length > 1
									? icons.dotted_line_inactive
									: icons.dotted_line}
							</p>
						</div>
						<div
							onClick={() => {
								navigate('/verification?kyc-stage=business-info');
							}}
							className="item__content"
						>
							<h5>Business Details</h5>
							<p>All your bill payment handled seamlessly</p>
							<span
								className={`${
									businessInfo?.industry?.length > 1 &&
									businessInfo?.transaction_range?.length > 1 &&
									'verified'
								} `}
							>
								<p>{businessInfo?.industry ? 'Verified' : 'Pending'}</p>
							</span>
						</div>
					</div>

					<div className="verification-steps__item">
						<div className="item__step-rail">
							<figure>
								{businessInfo?.address_status === 1
									? icons.info_checked
									: icons.info_orange}
							</figure>
							<p>
								{businessInfo?.address_status === 1
									? icons.dotted_line_inactive
									: icons.dotted_line}
							</p>
						</div>
						<div
							onClick={() => {
								navigate('/verification?kyc-stage=address');
							}}
							className="item__content"
						>
							<h5>Address Verification</h5>
							<p>All your bill payment handled seamlessly</p>
							<span
								className={`${
									businessInfo?.address_status === 1 && 'verified'
								}  `}
							>
								<p>
									{businessInfo?.address_status === 1
										? 'Verified'
										: businessInfo?.business_address?.length > 1
										? 'Ongoing Review'
										: 'Pending'}
								</p>
							</span>
						</div>
					</div>
				</div>

				<div className="onboarding__content-wrap">
					<MobileOnboardingSteps
						merchantInfo={merchantInfo}
						clearance={profile?.clearance}
						businessInfo={businessInfo}
					/>

					<div className="onboarding__learn-more">
						{/* <h5 className="learn-more__title">Learn more about Atlas</h5> */}
						<div className="learn-more__content">
							<div className="content__video">
								<figure className="video__play-icon">{icons.play}</figure>
								<div className="video__title">
									<h5>Atlas and your Business.</h5>
									<span>
										Be informed on how using the Atlas platform can be of great
										benefit to your business.
									</span>
								</div>
							</div>

							<div className="content__actions">
								<a
									href="https://raven-atlas.readme.io/reference/cards"
									target="_blank"
									rel="noopener noreferrer"
									className="content__documentation"
								>
									<figure className="documentation__illustration">
										<img src={keyBird} alt="" />
									</figure>
									<h5 className="documentation__title">Atlas Documentation</h5>
									<p>Start integrating, visit Atlas documentation.</p>
								</a>

								<a
									href="https://getravenbank.com/privacy-policy"
									target="_blank"
									rel="noopener noreferrer"
									className="content__documentation"
								>
									<figure className="documentation__illustration">
										<img src={privacyBird} alt="" />
									</figure>
									<h5 className="documentation__title">Data and Privacy</h5>
									<p>All your bill payment handled seamlessly</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
}

export default Onboarding;
