import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import SmartFilter from '../../../../components/common/smartFilter';
import './style/Compliance.css';
import Pagination from '../../../../components/common/pagination';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import { getActionNamesByPolicyName } from '../../../../utils/helper/Helper';
import EmptyScreen from '../../../../components/common/emptyScreen';
import PageLayout from '../../../../layout/dashboard/tableLayout';

interface MyComponentProps {
	children?: React.ReactNode;
	btnContent?: React.ReactNode;
}

const ComplianceLayout = ({ children, btnContent }: MyComponentProps) => {
	// const { profile } = useSelector((state: RootState) => state.settings);
	// const [activeTab, setActiveTab] = useState<string>('BVN Verification');
	const location = useLocation();
	const navigate = useNavigate();

	// Define tab routes and content
	const complianceRoutesList = [
		{
			name: 'BVN Verification',
			active:
				location?.pathname === '/dashboard-bankbox/compliance-bvn-verification',
			path: '/dashboard-bankbox/compliance-bvn-verification',
			// component: <ComplianceBvnVerification />,
			onClick: () => {
				navigate('/dashboard-bankbox/compliance-bvn-verification');
			},
		},
		{
			name: 'NIN Verification',
			active:
				location?.pathname === '/dashboard-bankbox/compliance-nin-verification',
			path: '/dashboard-bankbox/compliance-nin-verification',
			// component: <ComplianceNinVerification />,
			onClick: () => {
				navigate('/dashboard-bankbox/compliance-nin-verification');
			},
		},
		{
			name: 'Address Verification',
			active:
				location?.pathname ===
				'/dashboard-bankbox/compliance-address-verification',
			path: '/dashboard-bankbox/compliance-address-verification',
			// component: <ComplianceAddressVerification />,
			onClick: () => {
				navigate('/dashboard-bankbox/compliance-address-verification');
			},
		},
		{
			name: 'CAC Verification',
			active:
				location?.pathname === '/dashboard-bankbox/compliance-cac-verification',
			path: '/dashboard-bankbox/compliance-cac-verification',
			// component: <ComplianceCacVerification />,
			onClick: () => {
				navigate('/dashboard-bankbox/compliance-cac-verification');
			},
		},
		{
			name: 'Liveliness Check',
			active:
				location?.pathname === '/dashboard-bankbox/compliance-liveliness-check',
			path: '/dashboard-bankbox/compliance-liveliness-check',
			// component: <ComplianceLivelinessCheck />,
			onClick: () => {
				navigate('/dashboard-bankbox/compliance-liveliness-check');
			},
		},
	];

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'compliance'
		)?.includes('view_compliance')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	// Render component
	return (
		<DashboardLayout>
			<PageLayout
				pageTitle="Compliance"
				pageSubtitle="Manage all Disputes made on the platform"
			>
				<div className="com-layout-index-wrap-box">
					{/* Tabs Navigation */}
					<div className="children-route-wrapper-box white-faded-white-bg">
						<div className="route-path-wrap-box border-theme-bottom">
							{complianceRoutesList.map((tab, idx) => (
								<div
									className={`item-row ${tab?.active && 'item-row-active'}`}
									key={idx}
									onClick={() => {
										if (!tab?.active) {
											tab?.onClick && tab?.onClick();
										}
									}}
								>
									<p className="name grey-white-color">{tab.name}</p>
								</div>
							))}
							<div className="right-box-btn">{btnContent || ''}</div>
						</div>
						<>
							{/* dispute content start */}
							<div
								style={{ marginBottom: '1.5rem' }}
								className="global-search-pagination-filter-box"
							>
								{' '}
								<SmartFilter
									// hideExport
									searchTitle="Search By Email Address"
									defaultFilterBy="status"
									// filters={[
									// 	{
									// 		label: 'Status',
									// 		filterBy: 'status',
									// 		options: [
									// 			{
									// 				label: 'Pending',
									// 				value: 'pending',
									// 			},
									// 			{
									// 				label: 'Failed',
									// 				value: 'failed',
									// 			},
									// 			{
									// 				label: 'Successful',
									// 				value: 'succesful',
									// 			},
									// 		],
									// 	},
									// ]}
									onExport={() => {
										// 	setShowModal((prev) => {
										// 		return { ...prev, export: true };
										// 	});
									}}
									page="bankbox_transactions"
									onSearchChange={debounce((e) => {
										// dispatch(
										// 	bankboxAPI.getAllFees({
										// 		per_page: 20,
										// 		page: currentPage,
										// 		search: e,
										// 	})
										// );
									}, 500)}
								/>
								<div className="pagination-wrap">
									{' '}
									<Pagination
										className="top-bar__pagination"
										currentPage={0}
										itemsPerPage={0}
										totalItems={0}
										onPageChange={() => {}}
									/>
								</div>
							</div>
							{/* dispute content end */}
						</>
						{/* Render Active Tab's Content */}
						<div className="children-wrap-box">{children}</div>
					</div>
				</div>
			</PageLayout>
		</DashboardLayout>
	);
};

export default ComplianceLayout;
