import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TabLinksComponent = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const billsRoutesList = [
		{
			name: 'Airtime',
			path: '/dashboard-bankbox/bills-airtime',
			active: location?.pathname === '/dashboard-bankbox/bills-airtime',
			onClick: () => {
				navigate('/dashboard-bankbox/bills-airtime');
			},
			// component:<AirtimeBill/>
		},
		{
			name: 'Data',
			path: '/dashboard-bankbox/bills-data',
			active: location?.pathname === '/dashboard-bankbox/bills-data',
			onClick: () => {
				navigate('/dashboard-bankbox/bills-data');
			},
			// component:<DataBill/>
		},
		{
			name: 'Electricity',
			path: '/dashboard-bankbox/bills-electricity',
			active: location?.pathname === '/dashboard-bankbox/bills-electricity',
			onClick: () => {
				navigate('/dashboard-bankbox/bills-electricity');
			},
			// component:<ElectricityBill/>
		},
		{
			name: 'Cable TV',
			path: '/dashboard-bankbox/bills-cable-tv',
			active: location?.pathname === '/dashboard-bankbox/bills-cable-tv',
			// component:<CabletvBill/>
			onClick: () => {
				navigate('/dashboard-bankbox/bills-cable-tv');
			},
		},
		{
			name: 'Betting',
			path: '/dashboard-bankbox/bills-betting',
			active: location?.pathname === '/dashboard-bankbox/bills-betting',
			onClick: () => {
				navigate('/dashboard-bankbox/bills-betting');
			},
		},
	];
	return (
		<>
			<div className="route-path-wrap-box reuseable-wrapper-route-path-wrap-box border-theme-bottom">
				{billsRoutesList.map((tab, idx) => (
					<div
						className={`item-row ${tab?.active && 'item-row-active'}`}
						key={idx}
						onClick={() => {
							if (!tab?.active) {
								tab?.onClick && tab?.onClick();
							}
						}}
					>
						<p className="name grey-white-color">{tab.name}</p>
					</div>
				))}
				{/* <div className="right-box-btn">{btnContent || ''}</div> */}
			</div>
		</>
	);
};

export default TabLinksComponent;
