import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import { bettingData } from './bettingData';
import TwoDetailView from '../../../../../components/common/TwoDetailView';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import settlementIcon from '../../../../../assets/bank_box_icon_settlement.svg';
import './bettingIndex.css';
import BillsModal from '../billsModal';
import BillsLayout from '../billslayout';
import { debounce } from 'lodash';
import Pagination from '../../../../../components/common/pagination';
import SmartFilter from '../../../../../components/common/smartFilter';
const BettingBill = () => {
	type showModalProp = {
		bills_modal: boolean;
	};

	const [showModal, setShowModal] = useState<showModalProp>({
		bills_modal: false,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const { loading } = useSelector((state: RootState) => state.bankbox);

	// const bettingIcon = 

	return (
		<>
			<BillsLayout>
				{/* dispute content start */}
				<div
					style={{ marginBottom: '1.5rem' }}
					className="global-search-pagination-filter-box"
				>
					{' '}
					<SmartFilter
						// hideExport
						searchTitle="Search By Email Address"
						defaultFilterBy="status"
						// filters={[
						//  {
						//    label: 'Status',
						//    filterBy: 'status',
						//    options: [
						//      {
						//        label: 'Pending',
						//        value: 'pending',
						//      },
						//      {
						//        label: 'Failed',
						//        value: 'failed',
						//      },
						//      {
						//        label: 'Successful',
						//        value: 'succesful',
						//      },
						//    ],
						//  },
						// ]}
						onExport={() => {
							//  setShowModal((prev) => {
							//    return { ...prev, export: true };
							//  });
						}}
						page="bankbox_transactions"
						onSearchChange={debounce((e) => {
							// dispatch(
							//  bankboxAPI.getAllFees({
							//    per_page: 20,
							//    page: currentPage,
							//    search: e,
							//  })
							// );
						}, 500)}
					/>
					<div className="pagination-wrap">
						{' '}
						<Pagination
							className="top-bar__pagination"
							currentPage={0}
							itemsPerPage={1}
							totalItems={1}
							onPageChange={function (page: number): void {
								setCurrentPage(1);
							}}
						/>
					</div>
				</div>
				{/* dispute content end */}
				<div>
					{/* table content start */}
					<div className="transaction__table non-mobile">
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'MERCHANT DETAILS',
								'PROVIDER',
								'AMOUNT',
								'USER ID',
								'DATE',
								'STATUS',
							]}
						>
							{bettingData?.map((chi: any, idx: any) => (
								<RavenTableRow
									loading={loading}
									onRowClick={() => {
										setShowModal({ bills_modal: true });
									}}
									one={
										<TwoDetailView
											title={
												capitalizeFirstWord(
													formatTypeFunction(chi?.merchantDetails.name) as any
												) || '----'
											}
											text_one={chi.merchantDetails.email}
										/>
									}
									two={
										<div className="provider_image_and_name">
											<figure>
												{' '}
												<img src={settlementIcon} alt="image" />
											</figure>
											<p>{chi.provider.name}</p>
										</div>
									}
									three={chi?.amount}
									four={chi.userId}
									six={
										<div className="status_and_modal_holder">
											<BadgeComponent
												text={(() => {
													if (String(chi?.status === '0')) {
														return 'Disabled';
													} else {
														return 'Success';
													}
												})()}
												className={(() => {
													if (String(chi?.status === '1')) {
														return 'failed';
													} else {
														return 'success';
													}
												})()}
											/>
										</div>
									}
									five={<DateFragmentBox date={chi?.date} />}
								/>
							))}
						</RavenTable>
					</div>
					{/* table content end */}
				
				</div>
			</BillsLayout>
			<BillsModal
						visible={showModal.bills_modal}
						title="Transaction Details"
						onClose={() => setShowModal({ bills_modal: false })}
						amount={5000}
						businessEmail="aby1@buz.com"
						type="Top-up"
						fee={100}
						mobileNumber="080-222-333-11"
						provider="glo"
						references="REF-12345"
						merchantRef="MER-12345"
						status="0"
						date="2024-08-18T13:45:00"
						plan="Basic"
						btnLabel="Download Reciept"
						btnColor="green-light"
					/>
		</>
	);
};
export default BettingBill;
