import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import './styles/billsLayoutIndex.css';

import RootState from '../../../../redux/types';
import { useSelector } from 'react-redux';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import TabLinksComponent from './tabLinksComponent';

interface MyComponentProps {
	children?: React.ReactNode;
	btnContent?: React.ReactNode;
	pageLoading?: boolean;
	noContent?: boolean;
	noContentTitle?: string;
	noContentText?: string;
}

const BillsLayout = ({
	children,
	btnContent,
	noContent,
	pageLoading,
	noContentText,
	noContentTitle,
}: MyComponentProps) => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const location = useLocation();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const { all_fees, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageTitle="Bills"
					pageSubtitle="Manage all Bills made by merchants"
					topRightContent={<></>}
					loading={pageLoading}
					noContent={noContent}
					tabLinkComponent={<TabLinksComponent />}
					EmptyText={noContentText}
					EmptyTitle={noContentTitle}
				>
					<div className="bills-layout-index-wrap-box">
						{/* children wrapper b ox start  */}
						<div className="children-route-wrapper-box white-faded-white-bg bills_layout">
							{/* <div className="route-path-wrap-box border-theme-bottom">
								{billsRoutesList.map((tab, idx) => (
									<div
										className={`item-row ${tab?.active && 'item-row-active'}`}
										key={idx}
										onClick={() => {
											if (!tab?.active) {
												tab?.onClick && tab?.onClick();
											}
										}}
									>
										<p className="name grey-white-color">{tab.name}</p>
									</div>
								))}
								<div className="right-box-btn">{btnContent || ''}</div>
							</div> */}
							<></>
							{/* Render Active Tab's Content */}
							<div className="children-wrap-box">{children}</div>
						</div>
						{/* children wrapper b ox end */}
					</div>
				</PageLayout>
			</DashboardLayout>
		</>
	);
};

export default BillsLayout;
