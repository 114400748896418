import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import {
	ActivateBankBoxPayload,
	AssignBankBoxPayload,
	BankBoxCreateUserPayload,
	BankBoxState,
	BankboxMerchantProfile,
	GetAllBankBoxQueryParams,
} from './types';
import { logger } from '../services/logger';
import { toast } from '@ravenpay/raven-bank-ui';
import axios from '../utils/banbox_axios';
import mainAxios from '../utils/axios';
import defaultAxios from 'axios';
import { capitalizeFirstLetter, getCookie } from '../utils/helper/Helper';
import env from '../env';

export interface BankBoxRequests {
	createUser: AsyncThunk<any, BankBoxCreateUserPayload, any>;
	updateUser: AsyncThunk<any, BankBoxCreateUserPayload, any>;
	updateUserFee: AsyncThunk<any, any, any>;
	getDashboardSummary: any;
	uploadLogo: any;
	getTopTransacting: AsyncThunk<any, any, any>;
	getBankboxBanks: AsyncThunk<any, any, any>;
	getRoles: AsyncThunk<any, any, any>;
	getInvitationDetails: AsyncThunk<any, any, any>;
	acceptInviteTeamMember: AsyncThunk<any, any, any>;
	getAllTeams: AsyncThunk<any, any, any>;
	getAllSettlements: AsyncThunk<any, any, any>;
	getAllSettlementSearch: AsyncThunk<any, any, any>;
	getPolicies: AsyncThunk<any, any, any>;
	getSingleRole: AsyncThunk<any, any, any>;
	getSettingsConfigBankbox: AsyncThunk<any, any, any>;
	getBankboxPartner: AsyncThunk<any, any, any>;
	getBankboxStates: AsyncThunk<any, any, any>;
	getBankboxLocations: AsyncThunk<any, any, any>;
	getBankboxLgaByStates: AsyncThunk<any, any, any>;
	createFeeCategory: AsyncThunk<any, any, any>;
	editFeeCategory: AsyncThunk<any, any, any>;
	resendTeamInvite: AsyncThunk<any, any, any>;
	removeTeamMember: AsyncThunk<any, any, any>;
	inviteTeamMember: AsyncThunk<any, any, any>;
	manageTeamMember: AsyncThunk<any, any, any>;
	createRole: AsyncThunk<any, any, any>;
	updateRole: AsyncThunk<any, any, any>;
	createLocationPickUp: AsyncThunk<any, any, any>;
	editLocationPickUp: AsyncThunk<any, any, any>;
	changeRequestStatus: AsyncThunk<any, any, any>;
	sendCustomEmail: AsyncThunk<any, any, any>;
	changeDisputeStatus: AsyncThunk<any, any, any>;
	changeTerminalActiveDays: AsyncThunk<any, any, any>;
	getBankboxBankValidate: AsyncThunk<any, any, any>;
	addSettleMentAccountPartner: AsyncThunk<any, any, any>;
	editSettleMentAccountPartner: AsyncThunk<any, any, any>;
	requestSettleMentAccountPartner: AsyncThunk<any, any, any>;
	requestSettleMentAccountMerchant: AsyncThunk<any, any, any>;
	activateBankbox: AsyncThunk<any, ActivateBankBoxPayload, any>;
	getAllBankBox: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllBankBoxRequest: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllDisputes: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllFees: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllFeesWithoutPagination: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	exportUsers: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	exportDisputes: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	exportFees: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getProfile: AsyncThunk<any, any, any>;
	exportBankboxes: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	exportEODBankboxes: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	assignBankbox: AsyncThunk<any, AssignBankBoxPayload, any>;
	updateProfile: AsyncThunk<any, BankboxMerchantProfile, any>;
	deactivateBankbox: AsyncThunk<any, { serial_no: string }, any>;
	deleteBankbox: AsyncThunk<any, { serial_no: string }, any>;
	deleteLocation: AsyncThunk<any, { id: string }, any>;
	reactivateBankbox: AsyncThunk<any, { serial_no: string }, any>;
	setBankboxKey: AsyncThunk<any, { bankbox_key: string }, any>;
	getSingleUser: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getSingleUserMerchant: AsyncThunk<any, any, any>;
	getSingleBankbox: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getSingleBankboxFlier: AsyncThunk<any, { account_number: string }, any>;
	getAllUsers: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	// getSingle: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllTransactions: AsyncThunk<any, any, any>;
	getTransactionSingle: AsyncThunk<any, any, any>;
	getChartData: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getSingleTransaction: AsyncThunk<any, any, any>;
	exportBankboxTransaction: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	exportBankboxSettlement: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	// getAllTransactions: AsyncThunk<any, any, any>;
}

const getDashboardSummary = createAsyncThunk(
	'/bankbox/dashboardData',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/dashboard`);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setSummary(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getProfile = createAsyncThunk(
	'/bankbox/profile',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/get_merchant`);
			// logger.log(data?.data);
			//  console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setProfile(data?.data?.data));
				thunkAPI.dispatch(setPartnerSettlementInfo(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const inviteTeamMember = createAsyncThunk(
	'/atlas/pdon/invite-team-member',
	async (payload: BankBoxCreateUserPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/team/invite`, payload);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const manageTeamMember = createAsyncThunk(
	'/atlas/pdon/manage-team-member',
	async (payload: BankBoxCreateUserPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/team/update`, payload);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const createUser = createAsyncThunk(
	'/atlas/pdon/activate_bank_box',
	async (payload: BankBoxCreateUserPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/create_new_user`, payload);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const updateUser = createAsyncThunk(
	'/bankbox/updateUser',
	async (payload: BankBoxCreateUserPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/update_merchant`, payload);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const updateUserFee = createAsyncThunk(
	'/bankbox/updateUser-fee',
	async (payload: BankBoxCreateUserPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/merchant_change_fee`,
				payload
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const activateBankbox = createAsyncThunk(
	'/bankbox/onboard',
	async (payload: ActivateBankBoxPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/onboard`, payload);
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return data;
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const uploadLogo = createAsyncThunk(
	'/bankbox/upload',
	async (payload: FormData, thunkAPI) => {
		try {
			const { data } = await defaultAxios.post(
				`${env.bankbox_api}atlas/pdon/upload`,
				payload,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + getCookie('token'),
						'x-pos-key': getCookie('x-pos-key'),
					},
				}
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return data;
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const setBankboxKey = createAsyncThunk(
	'/bankbox/key_update',
	async (payload: { bankbox_key: string }, thunkAPI) => {
		try {
			const { data } = await mainAxios.post(`/web/set_bankbox_key`, payload);
			// console.log(data, '---------------kytyuioljh--------------');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return data;
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllBankBox = createAsyncThunk(
	'/bankbox/get_all_bankbox',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_bankbox?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}${
					payload.status ? '&status=' + payload.status : ''
				}${
					payload?.set_terminal_inactive_days
						? `&terminal_inactive_days=${payload?.set_terminal_inactive_days}`
						: ''
				}
        `
			);
			// console.log(data, '----------all terminals------');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setAllBankBox(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleRole = createAsyncThunk(
	'/bankbox/get_single_role',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/single_role?role_id=${payload?.role_id || ''}`
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setSingleRole(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getBankboxPartner = createAsyncThunk(
	'/bankbox/get_all_bankbox_partner',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/get_current_partner`);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBankBoxPartnerType(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getBankboxBanks = createAsyncThunk(
	'/bankbox/get_all_bankbox_banks',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/get_banks`);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getBankboxBankValidate = createAsyncThunk(
	'/bankbox/get_all_bankbox_bank_validate',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/account_number_lookup`,
				payload
			);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const addSettleMentAccountPartner = createAsyncThunk(
	'/bankbox/add-settle-ment-account-partner',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/add_settlement`, payload);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				toast.success(capitalizeFirstLetter(data?.data?.message), {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const editSettleMentAccountPartner = createAsyncThunk(
	'/bankbox/edit-settle-ment-account-partner',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/update_settlement`,
				payload
			);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				toast.success(capitalizeFirstLetter(data?.data?.message), {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const requestSettleMentAccountPartner = createAsyncThunk(
	'/bankbox/request-settle-ment-account-partner',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/process_partner_settlement`,
				payload
			);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				toast.success(capitalizeFirstLetter(data?.data?.message), {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			// console.log(error);

			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const requestSettleMentAccountMerchant = createAsyncThunk(
	'/bankbox/request-settle-ment-account-merchant',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/request_settlement`,
				payload
			);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				toast.success(capitalizeFirstLetter(data?.data?.message), {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			// console.log(error);

			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getBankboxStates = createAsyncThunk(
	'/bankbox/get_all_bankbox_states',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/regions`);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBankBoxStates(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getBankboxLgaByStates = createAsyncThunk(
	'/bankbox/get_all_bankbox_lga__states',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/lgas?region=${payload?.region}`
			);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBankBoxLga(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllBankBoxRequest = createAsyncThunk(
	'/bankbox/get_all_bankbox_request',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_bankbox_request?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.status ? '&status=' + payload.status : ''
				}${payload.range ? '&range=' + payload.range : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setAllBankBoxRequest(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllDisputes = createAsyncThunk(
	'/bankbox/get_all_dispute',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_disputes?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.status ? '&status=' + payload.status : ''
				}${payload.range ? '&range=' + payload.range : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setAllDisputes(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllFees = createAsyncThunk(
	'/bankbox/get_all_fees',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get/partners_fee_details?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setAllFees(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getBankboxLocations = createAsyncThunk(
	'/bankbox/get_all_locations',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_delivery_location?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data?.data?.data);

				thunkAPI.dispatch(setAllLocations(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllFeesWithoutPagination = createAsyncThunk(
	'/bankbox/get_all_fees_no_pagination',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/fee_categories?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data);

				thunkAPI.dispatch(setAllFeesNoPage(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportUsers = createAsyncThunk(
	'/bankbox/export_bankbox_users',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_bankbox_users?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportDisputes = createAsyncThunk(
	'/bankbox/export_disputes',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_disputes?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportFees = createAsyncThunk(
	'/bankbox/export_fees',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_fees?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${payload.range ? '&range=' + payload.range : ''}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportBankboxes = createAsyncThunk(
	'/bankbox/export_bankboxs',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_bankboxs?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportEODBankboxes = createAsyncThunk(
	'/bankbox/export_bankboxs_eod',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/generate_eod_report`,
				payload
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const createFeeCategory = createAsyncThunk(
	'/bankbox/create-fee-category',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(`/atlas/pdon/add/fee_details`, payload);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const editFeeCategory = createAsyncThunk(
	'/bankbox/edit-fee-category',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(`/atlas/pdon/update/fee`, payload);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const createLocationPickUp = createAsyncThunk(
	'/bankbox/create-location-pick-up',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/add_delivery_location`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const editLocationPickUp = createAsyncThunk(
	'/bankbox/edit-location-pick-up',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/update_delivery_location`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const checkEmailExist = createAsyncThunk(
	'/web/check_email_exist',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post('/atlas/pdon/check_partner', payload);
			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					// toast.error(data.data);
					return data;
				}
				// toast.error(data?.data?.message, {
				// 	position: 'top-right',
				// });
				else return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			// console.log(error);

			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getRoles = createAsyncThunk(
	'/bankbox/get_roles',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/role
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				thunkAPI.dispatch(setRoles(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const acceptInviteTeamMember = createAsyncThunk(
	'/bankbox/accept-invite-team-member',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/team/invite/accept`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllTeams = createAsyncThunk(
	'/bankbox/get_all_teams',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/team?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}${
					payload.status ? '&status=' + payload.status : ''
				}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				thunkAPI.dispatch(setTeams(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getPolicies = createAsyncThunk(
	'/bankbox/get_policies',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/policies`);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				thunkAPI.dispatch(setPolicies(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getInvitationDetails = createAsyncThunk(
	'/bankbox/get_invitation_details',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`atlas/pdon/team/invite/validate?invitation_token=${
					payload?.invitation_token || ''
				}`
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// thunkAPI.dispatch(setPolicies(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const sendCustomEmail = createAsyncThunk(
	'/bankbox/send-custom-email',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/send_custom_response`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const changeDisputeStatus = createAsyncThunk(
	'/bankbox/change-dispute-status',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/update_dispute_status`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const changeTerminalActiveDays = createAsyncThunk(
	'/bankbox/change-terminal-active-days',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`atlas/pdon/set_terminal_inactive_days`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const resendTeamInvite = createAsyncThunk(
	'/bankbox/resend-team-invites',
	async (payload: any, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(
				`/atlas/pdon/team/invite/resend`,
				payload
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				!payload?.dont_toast &&
					toast.success(data?.data?.message, {
						position: 'top-right',
					});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const removeTeamMember = createAsyncThunk(
	'/bankbox/remove-team-member',
	async (payload: any, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(`/atlas/pdon/team/delete`, payload);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const changeRequestStatus = createAsyncThunk(
	'/bankbox/change-request-status',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(`/atlas/pdon/update_request`, payload);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const assignBankbox = createAsyncThunk(
	'/bankbox/assign',
	async (payload: AssignBankBoxPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/assign_bank_box2`,
				payload
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const createRole = createAsyncThunk(
	'/bankbox/create-role',
	async (payload: AssignBankBoxPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/role/create`, payload);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const updateRole = createAsyncThunk(
	'/bankbox/update-role',
	async (payload: AssignBankBoxPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/role/update`, payload);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const updateProfile = createAsyncThunk(
	'/bankbox/update',
	async (payload: BankboxMerchantProfile, thunkAPI) => {
		// console.log(payload);

		try {
			const { data } = await axios.post(`/atlas/pdon/settings`, payload);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				//  console.log(data);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const deactivateBankbox = createAsyncThunk(
	'/bankbox/deactivate_bank_box',
	async (payload: { serial_no: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/deactivate_bank_box`,
				payload
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const deleteBankbox = createAsyncThunk(
	'/bankbox/delete_bank_box',
	async (payload: { serial_no: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/delete_terminal`, payload);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const deleteLocation = createAsyncThunk(
	'/bankbox/delete_location',
	async (payload: { id: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/delete_delivery_location`,
				payload
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const reactivateBankbox = createAsyncThunk(
	'/bankbox/activate_bank_box',
	async (payload: { serial_no: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/activate_bank_box`,
				payload
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllTransactions = createAsyncThunk(
	'/bankbox/get_all_bankbox_transactions',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_bankbox_transactions?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.status ? '&status=' + payload.status : ''}${
					payload.range ? '&range=' + payload.range : ''
				}${payload.serial_no ? '&serial=' + payload.serial_no : ''}${
					payload.type ? '&type=' + payload.type : ''
				}${payload.search ? '&search=' + payload.search : ''}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// console.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setAllBankBoxTrx(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getTransactionSingle = createAsyncThunk(
	'/bankbox/get_all_bankbox_transactions',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_bankbox_transactions?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.status ? '&status=' + payload.status : ''}${
					payload.range ? '&range=' + payload.range : ''
				}${payload.serial_no ? '&serial=' + payload.serial_no : ''}${
					payload.type ? '&type=' + payload.type : ''
				}${payload.search ? '&search=' + payload.search : ''}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// console.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setSingleBankBoxTrx(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleUser = createAsyncThunk(
	'/bankbox/get_single_user',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_single_user
        ${payload.email ? `?email=${payload.email || ''}` : ''}
        `
			);
			// logger.log(data?.data);
			// console.log(data, '--------------------');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setUser(data?.data?.data?.user));
				thunkAPI.dispatch(setSingleUserBankBoxes(data?.data?.data?.bankboxes));
				thunkAPI.dispatch(setSingleUserRequests(data?.data?.data?.requests));
				thunkAPI.dispatch(
					setSingleUserSettlement(data?.data?.data?.settlement_detail)
				);
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleUserMerchant = createAsyncThunk(
	'/bankbox/get_single_user_merchant',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_single_user${
					payload.email ? `?email=${payload.email || ''}` : ''
				}
        `
			);
			// logger.log(data?.data);
			console.log(data, '--------------------');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setUserMerchant(data?.data?.data));
				// thunkAPI.dispatch(setSingleUserBankBoxes(data?.data?.data?.bankboxes));
				// thunkAPI.dispatch(setSingleUserRequests(data?.data?.data?.requests));
				// thunkAPI.dispatch(setSingleUserSettlement(data?.data?.data?.settlement_detail));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getTopTransacting = createAsyncThunk(
	'/bankbox/get_top_transacting',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_top_transacting_users
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setTopTransacting(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleBankbox = createAsyncThunk(
	'/bankbox/get_single_bankbox',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_single_bankbox?${
					payload.serial_no ? 'serial_no=' + payload.serial_no : ''
				}
        `
			);
			// console.log(data);

			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setSingleBankBox(data.data.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				// toast.error(error.message, {
				// 	position: 'top-right',
				// });
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleBankboxFlier = createAsyncThunk(
	'/bankbox/single-babkox-flier',
	async (payload: { account_number: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/generate_flier`,
				// `/atlas/pdon/generate_flier`,
				payload
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				// console.log(data);

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllUsers = createAsyncThunk(
	'/bankbox/get_all_users',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// logger.log(payload, 'log');
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_users?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.region ? '&region=' + payload.region : ''
				}${payload.search ? '&search=' + payload.search : ''}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data, 'log');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAllUsers(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getChartData = createAsyncThunk(
	'/bankbox/get_chart_data',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_chart_data?
        ${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}
        ${payload.date ? '&date=' + payload.date : ''}
        `
			);

			// logger.log(data?.data, 'dataun');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}

			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setChartData(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleTransaction = createAsyncThunk(
	'/bankbox/get_bankbox_single_transaction',
	async (payload: { id: string }, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_bankbox_single_transaction?${
					payload.id ? 'id=' + payload.id : ''
				}

        `
			);
			// logger.log(data);
			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				// thunkAPI.dispatch(setAllBankBoxTrx(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const exportBankboxTransaction = createAsyncThunk(
	'/bankbox/export_bankbox_transactions',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_bankbox_transactions?${
					payload.affiliate_app_id
						? 'affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? 'start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.range ? '&range=' + payload.range : ''
				}${payload.search ? '&search=' + payload.search : ''}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportBankboxSettlement = createAsyncThunk(
	'/bankbox/export_bankbox_settlement',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_merchant_settlements?${
					payload.per_page ? 'per_page=' + payload.per_page : ''
				}${payload.page ? '&page=' + payload.page : ''}${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.stop_date ? '&stop_date=' + payload.stop_date : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSettingsConfigBankbox = createAsyncThunk(
	'/settings-config-bankbox',
	async (payload, thunkAPI) => {
		// console.log("-------------------------------------------");

		try {
			const data = await axios.get(`/atlas/pdon/bankbox_config`);
			// console.log(data);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setConfig(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllSettlements = createAsyncThunk(
	'/bankbox/get_all_settlements',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// logger.log(payload, 'log');
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_merchant_settlements?${
					payload.per_page ? 'perPage=' + payload.per_page : ''
				}${payload.page ? '&page=' + payload.page : ''}${
					payload.start_date ? '&start_date=' + payload.start_date : ''
				}${payload.stop_date ? '&stop_date=' + payload.stop_date : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${payload.status ? '&status=' + payload.status : ''}${
					payload.email ? '&email=' + payload.email : ''
				}
        `
			);
			// logger.log(data?.data, 'log');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				const obj = data?.data?.data;
				// console.log(obj);
				thunkAPI.dispatch(setSettlements(obj));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllSettlementSearch = createAsyncThunk(
	'/bankbox/get_all_settlements-search',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		// logger.log(payload, 'log');
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_merchant_settlements?${
					payload.search ? '&search=' + payload.search : ''
				}
        `
			);
			// logger.log(data?.data, 'log');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				const obj = data?.data?.data;
				// console.log(obj);
				thunkAPI.dispatch(setSettlements(obj));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const initialState: BankBoxState = {
	loading: false,
	loading_fee: false,
	loading_roles: false,
	loading_teams: false,
	isAuth: false,
	top_transacting: [],
	banks: [],
	profile: {},
	single_user_requests: [],
	single_user_bankboxes: [],
	users: {
		data: [],
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: null,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
	},
	all_bankbox_trx: {
		data: [],
		total: 0,
		perPage: 0,
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: null,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
	},
	single_bankbox_trx: {
		data: [],
		total: 0,
		perPage: 0,
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: null,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
	},
	dashboard_summary: {
		collectionBalance: 0,
		totalPendingRequest: 0,
		totalBankBoxDisbursed: 0,
		totalUsers: 0,
		merchantBalance: 0,
	},
	chart_data: {},
	settlement_detail: {},
	all_bankbox: {},
	all_bankbox_data: [],
	all_bankbox_request: {
		data: [],
		perPage: 0,
		currentPage: 0,
		nextPage: null,
		prevPage: null,
		totalPages: 0,
		total: 0,
	},
	all_disputes: {
		data: [],
		pagination: {
			per_page: 0,
			current_page: 0,
			next_page: null,
			prev_page: null,
			total_pages: 0,
			total: 0,
		},
	},
	all_fees: {
		data: [],
		pagination: {
			per_page: 0,
			current_page: 0,
			next_page: null,
			prev_page: null,
			total_pages: 0,
			total: 0,
		},
	},
	partner_info: {},
	user: {
		id: 0,
		fname: '',
		lname: '',
		poseidon_email: '',
		bvn: '',
		nin: '',
		phone: '',
		affiliate_app: '',
		affiliate_app_id: '',
		poseidon_business_name: '',
		poseidon_business_address: '',
		poseidon_business_type: null,
		poseidon_business_description: '',
		poseidon_profile_reference: '',
		business_category: '',
		region: '',
		created_at: '',
		updated_at: '',
		lga: '',
	},
	all_fees_no_page: [],
	single_bankbox: {},
	all_states: [],
	all_lga: [],
	all_locations: [],
	config: {},
	user_merchant: {},
	user_merchant_settlement: {},
	partner_settlement_info: {},
	policies: [],
	roles: [],
	teams: {},
	single_role: {},
	all_settlement: {},
	// initialize other state properties
};

const formatBankList = (param: any) => {
	if (param?.length > 0) {
		const newList = param?.map((chi: any) => {
			return { ...chi, label: chi?.name, value: chi?.code };
		});
		return newList;
	}
};

export const bankboxSlice = createSlice({
	name: 'bankbox',
	initialState,

	reducers: {
		setBankBoxUser: (state, action) => {
			state.user = action.payload;
			state.isAuth = true;
		},
		setTeams: (state, action) => {
			state.teams = action.payload;
			state.isAuth = true;
		},
		setBankBoxStates: (state, action) => {
			state.all_states = action.payload;
			state.isAuth = true;
		},
		setSingleRole: (state, action) => {
			state.single_role = action.payload;
			state.isAuth = true;
		},
		setBankBoxLga: (state, action) => {
			state.all_lga = action.payload;
			state.isAuth = true;
		},
		setBankBoxPartnerType: (state, action) => {
			state.partner_info = action.payload;
			state.partner_settlement_info = action.payload?.settlement_detail;
			state.isAuth = true;
		},
		setBankBoxBanks: (state, action) => {
			state.banks = action.payload;
			state.isAuth = true;
		},
		setAllUsers: (state, action) => {
			state.users = action.payload;
			state.isAuth = true;
		},
		setAllFees: (state, action) => {
			state.all_fees = action.payload;
			state.isAuth = true;
		},
		setAllLocations: (state, action) => {
			state.all_locations = action.payload;
			state.isAuth = true;
		},
		setAllFeesNoPage: (state, action) => {
			state.all_fees_no_page = action.payload;
			state.isAuth = true;
		},
		setUser: (state, action) => {
			state.user = action.payload;
			state.isAuth = true;
		},
		setSummary: (state, action) => {
			state.dashboard_summary = action.payload;
			state.isAuth = true;
		},
		setAllBankBox: (state, action) => {
			state.all_bankbox = action.payload;
			state.all_bankbox_data = action.payload?.data;
			state.isAuth = true;
		},
		setSingleBankBox: (state, action) => {
			state.single_bankbox = action.payload;
			state.isAuth = true;
		},
		setChartData: (state, action) => {
			state.chart_data = action.payload;
			state.isAuth = true;
		},

		setSingleUserBankBoxes: (state, action) => {
			state.single_user_bankboxes = action.payload;
			state.isAuth = true;
		},
		setSingleUserRequests: (state, action) => {
			state.single_user_requests = action.payload;
			state.isAuth = true;
		},
		setSingleUserSettlement: (state, action) => {
			state.settlement_detail = action.payload;
			state.isAuth = true;
		},
		setAllBankBoxRequest: (state, action) => {
			state.all_bankbox_request = action.payload;
			state.isAuth = true;
		},
		setAllDisputes: (state, action) => {
			state.all_disputes = action.payload;
			state.isAuth = true;
		},
		setTopTransacting: (state, action) => {
			state.top_transacting = action.payload;
			state.isAuth = true;
		},
		setPolicies: (state, action) => {
			state.policies = action.payload;
			state.isAuth = true;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
			state.isAuth = true;
		},
		setAllBankBoxTrx: (state, action) => {
			state.all_bankbox_trx = action.payload;
			state.isAuth = true;
		},
		setSingleBankBoxTrx: (state, action) => {
			state.single_bankbox_trx = action.payload;
			state.isAuth = true;
		},
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
		setPartnerSettlementInfo: (state, action) => {
			// state.partner_settlement_info = action.payload;
			state.isAuth = true;
		},
		setSettlements: (state, action) => {
			state.all_settlement = action.payload;
		},
		setConfig: (state, action) => {
			state.config = action.payload;
			state.isAuth = true;
		},
		setUserMerchant: (state, action) => {
			state.user_merchant = action.payload;
			state.user_merchant_settlement = action.payload?.settlement_detail;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		// Pending state
		builder
			.addCase(createUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllTeams.pending, (state) => {
				state.loading_teams = true;
			})
			.addCase(getRoles.pending, (state) => {
				state.loading_roles = true;
			})
			.addCase(updateUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(addSettleMentAccountPartner.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(editSettleMentAccountPartner.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(requestSettleMentAccountPartner.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(requestSettleMentAccountMerchant.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(updateUserFee.pending, (state) => {
				state.loading = true;
			})
			.addCase(getBankboxLocations.pending, (state) => {
				state.loading = true;
			})
			.addCase(sendCustomEmail.pending, (state) => {
				state.loading = true;
			})
			.addCase(getDashboardSummary.pending, (state) => {
				state.loading = true;
			})
			.addCase(activateBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBankBox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllSettlements.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllSettlementSearch.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBankBoxRequest.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportBankboxes.pending, (state) => {
				state.loading = true;
			})
			.addCase(assignBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(deactivateBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleUserMerchant.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(getChartData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleTransaction.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportBankboxTransaction.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportBankboxSettlement.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(getAllTransactions.pending, (state) => {
				state.loading = true;
			})
			.addCase(getProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateProfile.pending, (state) => {
				state.loading = true;
			})
			// .addCase(uploadLogo.pending, (state) => {
			// 	state.loading = true;
			// })
			.addCase(reactivateBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(setBankboxKey.pending, (state) => {
				state.loading = true;
			})
			.addCase(createFeeCategory.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(editFeeCategory.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(getAllFees.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleRole.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(changeRequestStatus.pending, (state) => {
				state.loading_fee = true;
			})
			.addCase(getAllDisputes.pending, (state) => {
				state.loading = true;
			});
		// Fulfilled state
		builder
			.addCase(createUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllTeams.fulfilled, (state) => {
				state.loading_teams = false;
			})
			.addCase(getAllSettlements.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllSettlementSearch.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getRoles.fulfilled, (state) => {
				state.loading_roles = false;
			})
			.addCase(addSettleMentAccountPartner.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(editSettleMentAccountPartner.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(exportBankboxSettlement.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(requestSettleMentAccountPartner.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(requestSettleMentAccountMerchant.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(updateUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateUserFee.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getBankboxLocations.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(sendCustomEmail.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllFees.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(createFeeCategory.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(editFeeCategory.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(getAllDisputes.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getDashboardSummary.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(activateBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllBankBox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllBankBoxRequest.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(exportUsers.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(exportBankboxes.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(assignBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deactivateBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleUserMerchant.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllUsers.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getChartData.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleTransaction.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(exportBankboxTransaction.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllTransactions.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getProfile.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateProfile.fulfilled, (state) => {
				state.loading = false;
			})
			// .addCase(uploadLogo.fulfilled, (state) => {
			// 	state.loading = false;
			// })
			.addCase(reactivateBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(changeRequestStatus.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(getSingleRole.fulfilled, (state) => {
				state.loading_fee = false;
			})
			.addCase(setBankboxKey.fulfilled, (state) => {
				state.loading = false;
			});

		// Rejected state
		builder
			.addCase(createUser.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getRoles.rejected, (state) => {
				state.loading_roles = false;
			})
			.addCase(getAllSettlements.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllSettlementSearch.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllTeams.rejected, (state) => {
				state.loading_teams = false;
			})
			.addCase(addSettleMentAccountPartner.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(editSettleMentAccountPartner.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(exportBankboxSettlement.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(requestSettleMentAccountPartner.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(requestSettleMentAccountMerchant.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(updateUser.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateUserFee.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getBankboxLocations.rejected, (state) => {
				state.loading = false;
			})
			.addCase(sendCustomEmail.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllFees.rejected, (state) => {
				state.loading = false;
			})
			.addCase(createFeeCategory.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(editFeeCategory.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(getAllDisputes.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getDashboardSummary.rejected, (state) => {
				state.loading = false;
			})
			.addCase(activateBankbox.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllBankBox.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllBankBoxRequest.rejected, (state) => {
				state.loading = false;
			})
			.addCase(exportUsers.rejected, (state) => {
				state.loading = false;
			})
			.addCase(exportBankboxes.rejected, (state) => {
				state.loading = false;
			})
			.addCase(assignBankbox.rejected, (state) => {
				state.loading = false;
			})
			.addCase(deactivateBankbox.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getSingleUser.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getSingleUserMerchant.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getSingleBankbox.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllUsers.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getChartData.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getSingleTransaction.rejected, (state) => {
				state.loading = false;
			})
			.addCase(exportBankboxTransaction.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllTransactions.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getProfile.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateProfile.rejected, (state) => {
				state.loading = false;
			})
			// .addCase(uploadLogo.rejected, (state) => {
			// 	state.loading = false;
			// })
			.addCase(reactivateBankbox.rejected, (state) => {
				state.loading = false;
			})
			.addCase(setBankboxKey.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(getSingleRole.rejected, (state) => {
				state.loading_fee = false;
			})
			.addCase(changeRequestStatus.rejected, (state) => {
				state.loading_fee = false;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setBankBoxUser,
	setRoles,
	setSummary,
	setAllBankBox,
	setAllBankBoxRequest,
	setAllBankBoxTrx,
	setAllUsers,
	setChartData,
	setTopTransacting,
	setPolicies,
	setUser,
	setProfile,
	setPartnerSettlementInfo,
	setSingleUserBankBoxes,
	setAllDisputes,
	setAllFees,
	setSingleUserRequests,
	setSingleUserSettlement,
	setAllFeesNoPage,
	setBankBoxPartnerType,
	setBankBoxBanks,
	setSingleBankBox,
	setBankBoxLga,
	setBankBoxStates,
	setAllLocations,
	setConfig,
	setSingleBankBoxTrx,
	setUserMerchant,
	setTeams,
	setSingleRole,
	setSettlements,
} = bankboxSlice.actions;

export const bankboxAPI: BankBoxRequests = {
	editLocationPickUp,
	resendTeamInvite,
	removeTeamMember,
	acceptInviteTeamMember,
	inviteTeamMember,
	manageTeamMember,
	createUser,
	updateUser,
	updateUserFee,
	getDashboardSummary,
	activateBankbox,
	getAllBankBox,
	getAllBankBoxRequest,
	exportUsers,
	exportBankboxes,
	getAllDisputes,
	exportEODBankboxes,
	getTopTransacting,
	assignBankbox,
	deactivateBankbox,
	reactivateBankbox,
	getSingleUser,
	getSingleUserMerchant,
	getSingleBankbox,
	getProfile,
	getAllUsers,
	getChartData,
	getSingleTransaction,
	exportBankboxTransaction,
	updateProfile,
	getAllTransactions,
	getTransactionSingle,
	uploadLogo,
	setBankboxKey,
	createFeeCategory,
	editFeeCategory,
	getAllFees,
	getRoles,
	sendCustomEmail,
	changeDisputeStatus,
	exportDisputes,
	exportFees,
	getAllFeesWithoutPagination,
	changeRequestStatus,
	getBankboxPartner,
	deleteBankbox,
	getBankboxStates,
	getBankboxLgaByStates,
	createLocationPickUp,
	getBankboxLocations,
	deleteLocation,
	getSettingsConfigBankbox,
	addSettleMentAccountPartner,
	editSettleMentAccountPartner,
	getSingleBankboxFlier,
	getBankboxBanks,
	getBankboxBankValidate,
	requestSettleMentAccountPartner,
	requestSettleMentAccountMerchant,
	getPolicies,
	createRole,
	getAllTeams,
	getInvitationDetails,
	getSingleRole,
	updateRole,
	getAllSettlements,
	getAllSettlementSearch,
	exportBankboxSettlement,
	changeTerminalActiveDays,
};

export default bankboxSlice.reducer;

// smiles i used a different approach here
