import React, { useEffect, useState } from 'react';
import SettingsLayout from '../SettingsLayout';
import './styles/RolesPermissionIndex.css';
import {
	capitalizeFirstLetter,
	getActionNamesByPolicyName,
	removeSpace,
	returnInitial,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';
import { RavenModal } from '@ravenpay/raven-bank-ui';
import CreateRoleAndPermision from '../../components/modals/CreateRoleModal';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';
import NoContentBox from '../../components/no-content/NoContentBox';
import EmptyScreen from '../../../../../components/common/emptyScreen';

const RolesPermissionIndex = () => {
	const dispatch = useDispatch();

	const { loading_roles, policies, roles } = useSelector(
		(state: RootState) => state.bankbox
	);
	const navigate = useNavigate();
	const maxNumber = 4;
	const roleList = [
		{
			name: 'Owner',
			des: 'This is owner of the account, and this user cannot be removed or changed at any-point in time.',
			users: ['ade Op', 'kell Noah', 'Jude uya'],
		},
		{
			name: 'Administrators',
			des: 'This is an administrative role, this role houses all team members in administration',
			users: ['ade Op', 'kell Noah', 'Jude uya'],
		},
		{
			name: 'Operations',
			des: 'This is Operations role, this role houses all team members in operations',
			users: ['ade Op', 'kell Noah', 'Jude uya'],
		},
		{
			name: 'Customer Support',
			des: 'This is a customer support role, this role houses all team members in customer support',
			users: ['ade Op', 'kell Noah', 'Jude uya'],
		},
		{
			name: 'Finance',
			des: 'TThis is a finance role, this role houses all team members in finance.',
			users: [
				'ade Op',
				'kell Noah',
				'Jude uya',
				'Ajayi Crowder',
				'James Ochapa',
				'Neslson Man',
			],
		},
	];

	const plusIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="166"
			height="166"
			fill="none"
			viewBox="0 0 166 166"
			className="img"
		>
			<path
				stroke="#ACACAC"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M83 34.582v96.833M34.584 82.999h96.833"
			></path>
		</svg>
	);

	type modalProps = {
		add: boolean;
		delete: boolean;
	};

	const [showModal, setShowModal] = useState<modalProps>({
		add: false,
		delete: false,
	});

	useEffect(() => {
		// dispatch(bankboxAPI?.getRoles({}));
		if (policies?.length < 1) {
			dispatch(bankboxAPI?.getPolicies({}));
		}

		if (roles?.length < 1) {
			dispatch(bankboxAPI?.getRoles({}));
		}
	}, []);

	const arrowRightIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			fill="none"
			viewBox="0 0 28 28"
			className="img"
		>
			<path
				stroke="#755AE2"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M10.5 21l7-7-7-7"
			></path>
		</svg>
	);

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'roles'
		)?.includes('view_roles')
	) {
		return (
			<SettingsLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</SettingsLayout>
		);
	}

	return (
		<>
			<SettingsLayout>
				<div
					onClick={() => {
						// console.log(profile?.rolePolicies?.policies);
						// console.log(
						// 	getActionNamesByPolicyName(
						// 		profile?.rolePolicies?.policies,
						// 		'compliance'
						// 	)
						// );
					}}
					className="role-and-permission-wrap-box-index"
				>
					{/* roles list box start */}
					{loading_roles ? (
						<>
							<NoContentBox
								loading={true}
								text="No roles recorded for now. Roles created would get to show up here."
							/>
						</>
					) : (
						<div
							className={`role-list-box ${
								roles?.length < 3 ? 'role-list-box-two' : ''
							}`}
						>
							{roles?.map((chi: any, idx: any) => {
								const { roleDescription, roleName, teamMembers } = chi;
								return (
									<div
										onClick={() => {
											if (
												getActionNamesByPolicyName(
													profile?.rolePolicies?.policies,
													'roles'
												)?.includes('view_roles')
											) {
												localStorage?.setItem('single-role-id', chi?.roleId);
												navigate('/dashboard-bankbox/settings-roles-single');
											}
										}}
										key={idx}
										className="role-item border-theme"
									>
										<p className="name">{capitalizeFirstLetter(roleName)}</p>
										<p className="des">
											{trimLongString(roleDescription, 150)}
										</p>

										<div className="users-arrow-box">
											<div className="avatar-users-box">
												{teamMembers?.length > 0 ? (
													<>
														{' '}
														{teamMembers
															?.slice(0, maxNumber)
															?.map((chi: any, idx: any) => {
																return (
																	<div
																		key={idx}
																		className="avatar-box border-theme grey-bg"
																	>
																		<span>
																			{returnInitial(
																				`${removeSpace(chi?.firstName) || ''} ${
																					chi?.lastName || ''
																				}`
																			)}
																		</span>
																	</div>
																);
															})}
														{teamMembers?.length > maxNumber && (
															<>
																<div className="avatar-box border-theme grey-bg">
																	<span>
																		+{teamMembers?.length - maxNumber}
																	</span>
																</div>
															</>
														)}
													</>
												) : (
													<>
														<p className="grey-white-color">No Team Member</p>
													</>
												)}
											</div>
											<div className="arrow-box grey-bg">
												<figure className="img-box">{arrowRightIcon}</figure>
											</div>
										</div>
									</div>
								);
							})}
							<div
								onClick={() => {
									setShowModal((prev) => {
										return { ...prev, add: true };
									});
								}}
								className="role-item grey-bg"
							>
								<div className="middle-icon">
									<figure className="img-box">{plusIcon}</figure>
								</div>
							</div>
						</div>
					)}
					{/* roles list box end */}
				</div>
			</SettingsLayout>

			{/* crreat role start */}
			<CreateRoleAndPermision
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, add: false };
					});
				}}
				visible={showModal?.add}
				onComplete={() => {
					dispatch(bankboxAPI?.getRoles({}));
				}}
			/>
		</>
	);
};

export default RolesPermissionIndex;
