import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import './styles/SettingsLayout.css';
import EmptyScreen from '../../../../components/common/emptyScreen';
import RootState from '../../../../redux/types';
import { useSelector } from 'react-redux';
import { getActionNamesByPolicyName } from '../../../../utils/helper/Helper';

interface MyComponentProps {
	children?: React.ReactNode;
	btnContent?: React.ReactNode;
}

const SettingsLayout = ({ children, btnContent }: MyComponentProps) => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const location = useLocation();
	const navigate = useNavigate();

	const settingRoutesList = [
		{
			name: 'General',
			link: '/dashboard-bankbox/settings-general',
			active: location?.pathname === '/dashboard-bankbox/settings-general',
		},
		{
			name: 'Team Management',
			link: '/dashboard-bankbox/settings-teams',
			active: location?.pathname === '/dashboard-bankbox/settings-teams',
		},
		{
			name: 'Roles and Permissions',
			link: '/dashboard-bankbox/settings-roles-and-permission',
			active:
				location?.pathname ===
				'/dashboard-bankbox/settings-roles-and-permission',
			hide: getActionNamesByPolicyName(
				profile?.rolePolicies?.policies,
				'roles'
			)?.includes('view_roles')
				? false
				: true,
		},
	];

	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'settings'
		)?.includes('view_settings')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout>
				<div className="settings-layout-index-wrap-box">
					<div className="settings__title">
						<div className="title__main">
							<h5>BankBox Settings</h5>
							<p>Manage your account and profile settings</p>
						</div>
					</div>
					{/* children wrapper b ox start  */}
					<div className="children-route-wrapper-box white-faded-white-bg">
						{/* route path box start */}
						<div className="route-path-wrap-box border-theme-bottom">
							{settingRoutesList?.map((chi, idx) => {
								if (chi?.hide) {
									return <React.Fragment key={idx}></React.Fragment>;
								}
								return (
									<div
										className={`item-row ${
											location?.pathname?.includes(chi?.link) &&
											'item-row-active'
										}`}
										key={idx}
										onClick={() => {
											if (!location?.pathname?.includes(chi?.link)) {
												navigate(chi?.link);
											}
										}}
									>
										<p className="name grey-white-color">{chi?.name}</p>
									</div>
								);
							})}
							<div className="right-box-btn">{btnContent || ''}</div>
						</div>
						{/* route path box end */}
						{/* chidren box start */}
						<div className="children-wrap-box">{children}</div>
						{/* chidren box end */}
					</div>
					{/* children wrapper b ox end */}
				</div>
			</DashboardLayout>
		</>
	);
};

export default SettingsLayout;
