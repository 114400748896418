import BankBoxOverview from '../../../pages/dashboard/bankbox';
import BankBoxUsers from '../../../pages/dashboard/bankbox/users';
import BankboxRequest from '../../../pages/dashboard/bankbox/components/request';
import BankboxTerminals from '../../../pages/dashboard/bankbox/terminals';
import BankBoxTransactions from '../../../pages/dashboard/bankbox/transactions';
import BankboxSettings from '../../../pages/dashboard/bankbox/settings';
import SingleViewBankbox from '../../../pages/dashboard/bankbox/terminals/component/SingleViewBankbox';
import Onboarding from '../../../pages/dashboard/onboarding';
import DisputeIndexPage from '../../../pages/dashboard/bankbox/disputes';
import FeeDetailsIndexPage from '../../../pages/dashboard/bankbox/fee-details';
import BankBoxUserPreview from '../../../pages/dashboard/bankbox/users/component/UserPreview';
import SingleBankBoxTransactions from '../../../pages/dashboard/bankbox/transactions/SingleTerminalTransactions';
import TeamSettingsIndex from '../../../pages/dashboard/bankbox/settings/teams/TeamSettingsIndex';
import RolesPermissionIndex from '../../../pages/dashboard/bankbox/settings/role-and-permission/RolesPermissionIndex';
import SingleRoleIndex from '../../../pages/dashboard/bankbox/settings/role-and-permission/SingleRoleIndex';
import Settlement from "../../../pages/dashboard/bankbox/settlements"
import Bills from '../../../pages/dashboard/bankbox/bills';
import AirtimeBill from '../../../pages/dashboard/bankbox/bills/airtime';
import DataBill from '../../../pages/dashboard/bankbox/bills/dataBill';
import ElectricityBill from '../../../pages/dashboard/bankbox/bills/electricityBill';
import CableTvBill from '../../../pages/dashboard/bankbox/bills/cabletvBill';
import BettingBill from '../../../pages/dashboard/bankbox/bills/bettingBill';
// import Compliance from '../../../pages/dashboard/bankbox/compliance';
import ComplianceBvnVerification from '../../../pages/dashboard/bankbox/compliance/compliance-bvn-verification';
import ComplianceNinVerification from '../../../pages/dashboard/bankbox/compliance/compliance-nin-verification';
import ComplianceAddressVerification from '../../../pages/dashboard/bankbox/compliance/compliance-address-verification';
import ComplianceCacVerification from '../../../pages/dashboard/bankbox/compliance/compliance-cac-verification';
// import complianceLivelinessCheck from '../../../pages/dashboard/bankbox/compliance/compliance-liveliness-check';
import ComplianceLivelinessCheck from '../../../pages/dashboard/bankbox/compliance/compliance-liveliness-check';


export const overview_routes_group = [
	{
		path: '/dashboard-overview',
		element: BankBoxOverview,
	},
	{
		path: '/dashboard-bankbox/users',
		element: BankBoxUsers,
	},

	{
		path: '/dashboard-bankbox/user-single',
		element: BankBoxUserPreview,
	},
	{
		path: '/dashboard-onboarding',
		element: Onboarding,
	},
	{
		path: '/dashboard-bankbox/requests',
		element: BankboxRequest,
	},

	{
		path: '/dashboard-bankbox/terminals',
		element: BankboxTerminals,
	},
	{
		path: '/dashboard-bankbox/terminals/view',
		element: SingleViewBankbox,
	},
	{
		path: '/dashboard-bankbox/transactions',
		element: BankBoxTransactions,
	},
	{
		path: '/dashboard-bankbox/transaction-single',
		element: SingleBankBoxTransactions,
	},
	{
		path: '/dashboard-bankbox/compliance-bvn-verification',
		element: ComplianceBvnVerification,
	},
	{
		path: '/dashboard-bankbox/compliance-nin-verification',
		element: ComplianceNinVerification,
	},
	{
		path: '/dashboard-bankbox/compliance-address-verification',
		element: ComplianceAddressVerification,
	},
	{
		path: '/dashboard-bankbox/compliance-cac-verification',
		element: ComplianceCacVerification,
	},
	{
		path: '/dashboard-bankbox/compliance-liveliness-check',
		element: ComplianceLivelinessCheck,
	},
	{
		path: '/dashboard-bankbox/settings-general',
		element: BankboxSettings,
	},
	{
		path: '/dashboard-bankbox/settings-teams',
		element: TeamSettingsIndex,
	},
	{
		path: '/dashboard-bankbox/settings-roles-and-permission',
		element: RolesPermissionIndex,
	},
	{
		path: '/dashboard-bankbox/settings-roles-single',
		element: SingleRoleIndex,
	},
	{
		path: '/dashboard-bankbox/disputes',
		element: DisputeIndexPage,
	},
	{
		path: '/dashboard-bankbox/fee-details',
		element: FeeDetailsIndexPage,
	},
	{
    path:"/dashboard-bankbox/settlement",
    element: Settlement
  
  },
	
	{
    path:"/dashboard-bankbox/bills-airtime",
    element: AirtimeBill
  
  },
	{
    path:"/dashboard-bankbox/bills-data",
    element: DataBill
  
  },
	{
    path:"/dashboard-bankbox/bills-electricity",
    element: ElectricityBill
  
  },
	{
    path:"/dashboard-bankbox/bills-cable-tv",
    element: CableTvBill
  
  },
	{
    path:"/dashboard-bankbox/bills-betting",
    element: BettingBill
  
  },
];
