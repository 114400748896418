import PageLayout from '../../../../layout/dashboard/tableLayout';
import './styles/settlementIndex.css';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import EmptyScreen from '../../../../components/common/emptyScreen';
import SmartFilter from '../../../../components/common/smartFilter';
import Pagination from '../../../../components/common/pagination';
import { settlementData } from './settlementData';
import { FaRegEye } from 'react-icons/fa';
import { icons } from '../../../../assets/icons';
import {
	capitalizeFirstLetter,
	capitalizeFirstWord,
	downloadCsvFile,
	formatDateHelper,
	formatMetaData,
	formatTypeFunction,
	getActionNamesByPolicyName,
	trimLongString,
} from '../../../../utils/helper/Helper';
import {
	RavenModal,
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import DateFragmentBox from '../../../../components/common/DateFragmentBox';
import TwoDetailView from '../../../../components/common/TwoDetailView';
import BadgeComponent from '../../../../components/common/badge/BadgeComponent';
import EditFeeCategoryModal from '../fee-details/EditFeeCategoryModal';
import SettlementModal from './settlementModal';
import { bankboxAPI } from '../../../../redux/bankbox';
import { debounce } from 'lodash';
import ExportModal from '../../../../components/common/exportModal';

const Settlement = () => {
	const [showExport, setShowExport] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const { profile } = useSelector((state: RootState) => state.settings);
	const dispatch = useDispatch();
	const [noContent, setNoContent] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [singleChild, setSingleChild] = useState<any>({});
	const { all_settlement, loading, loading_fee } = useSelector(
		(state: RootState) => state.bankbox
	);

	useEffect(() => {
		const obj = {
			per_page: 20,
			page: currentPage,
		};
		dispatch(bankboxAPI.getAllSettlements(obj));
	}, [currentPage]);

	const csvHeader = [
		{ label: 'S/N', key: 'sn' },
		{ label: 'Business Name', key: 'merchant_name' },
		{ label: 'Business Email', key: 'merchant_email' },
		{ label: 'Type', key: 'type' },
		{ label: 'Amount', key: 'amount' },
		{ label: 'Account Name', key: 'account_name' },
		{ label: 'Account Number', key: 'account_number' },
		{ label: 'Bank', key: 'bank' },
		{ label: 'Transaction Reference', key: 'tranx_reference' },
		{ label: 'Transaction Details', key: 'transaction_details' },
		{ label: 'Date', key: 'created_at' },
		{ label: 'Status', key: 'status' },
	];
	const formatExportData = (param: any) => {
		if (param?.length > 0) {
			const newData = param?.map((chi: any, idx: any) => {
				return {
					sn: idx + 1 < 10 ? `0${idx + 1}` : idx + 1,
					merchant_name: chi?.business_profile?.business_name
						? capitalizeFirstLetter(chi?.business_profile?.business_name)
						: 'NIL',
					merchant_email: chi?.business_profile?.business_email || 'NIL',
					type: chi?.type ? formatTypeFunction(chi?.type) : 'NIL',
					amount: RavenNumberFormat(chi?.amount) || 'NIL',
					account_name: chi?.account_details?.account_name || 'NIL',
					account_number: chi?.account_details?.account_number || 'NIL',
					bank: chi?.account_details?.bank || 'NIL',
					tranx_reference: chi?.trx_ref || 'NIL',
					created_at: chi?.created_at
						? formatDateHelper(chi?.created_at)?.replace('—', '•')
						: 'NIL',
					status:
						String(chi?.status) === '0'
							? `Pending`
							: String(chi?.status) === '1'
							? `Pending`
							: String(chi?.status) === '2'
							? `Failed`
							: String(chi?.status) === '3'
							? `Successful`
							: `Failed`,
				};
			});

			downloadCsvFile(csvHeader, newData, 'settlment_data.csv');
		}
	};

	// if (
	// 	!getActionNamesByPolicyName(
	// 		profile?.rolePolicies?.policies,
	// 		'settlement'
	// 	)?.includes('settlement')
	// ) {
	// 	return (
	// 		<DashboardLayout>
	// 			<>
	// 				<EmptyScreen
	// 					loading={Object?.keys(profile)?.length < 1 ? true : false}
	// 					title="Permission Denied"
	// 					subTitle="You have no permission to access this page, Please contact admin or support."
	// 				></EmptyScreen>
	// 			</>
	// 		</DashboardLayout>
	// 	);
	// }
	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="View settlement history with merchants"
					pageTitle="Settlement"
					topRightContent={<></>}
					loading={loading}
					noContent={Object?.keys(all_settlement)?.length < 1 || all_settlement?.data?.length < 1}
					LoadingText="Loadng Settlements..."
					EmptyText="You don't have any record yet,your new bankbox settlement will be displayed here."
					EmptyTitle='No Settlement Found'
				>
					<div className="input_filter_export_general_wrap">
						<SmartFilter
							// hideFilter
							//  hideExport
							searchTitle="Search settlements"
							defaultFilterBy="status"
							onSearchChange={debounce((e) => {
								dispatch(
									bankboxAPI.getAllSettlementSearch({
										per_page: 20,
										search: e,
									})
								);
							}, 500)}
							filters={[
								{
									label: 'Status',
									filterBy: 'status',
									options: [
										{
											label: 'Pending',
											value: '1',
										},
										{
											label: 'Failed',
											value: '2',
										},
										{
											label: 'Successful',
											value: '3',
										},
										{
											label: 'All Status',
											value: 'reset',
										},
									],
								},
							]}
							onExport={() => {
								setShowExport(true);
							}}
							page="bankbox_settlements"
							// onSearchChange={debounce((e) => {
							// 	dispatch(
							// 		bankboxAPI.getAllFees({
							// 			per_page: 20,
							// 			page: currentPage,
							// 			search: e,
							// 		})
							// 	);
							// }, 500)}
						/>

						<div className="pagination-wrap">
							{' '}
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={all_settlement?.pagination?.perPage}
								totalItems={all_settlement?.pagination?.total}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>
					</div>

					<div
						// onClick={() => {
						// 	console.log(all_settlement);
						// }}
						className="fee-detail-dashboard-content-index-wrap"
					>
						{/* table content start */}
						<div className="transaction__table non-mobile">
							<RavenTable
								action={false}
								className="table__main"
								headerList={[
									'MERCHANT',
									'BANK DETAILS',
									'TYPE',
									'AMOUNT',
									'DATE',
									'STATUS',
								]}
							>
								{all_settlement?.data?.map((chi: any, idx: any) => (
									<RavenTableRow
										loading={loading}
										onRowClick={() => {
											// setIsModalOpen(true);
										}}
										key={idx}
										one={
											<TwoDetailView
												title={
													trimLongString(
														capitalizeFirstWord(
															chi?.business_profile?.business_name
														),
														25
													) || '----'
												}
												text_one={trimLongString(
													chi?.business_profile?.email,
													30
												)}
											/>
										}
										two={
											<TwoDetailView
												title={
													capitalizeFirstWord(
														trimLongString(
															chi?.account_details?.account_name,
															25
														) as any
													) || '----'
												}
												text_one={chi?.account_details?.bank}
												text_two={chi?.account_details?.account_number}
											/>
										}
										three={
											trimLongString(formatTypeFunction(chi?.type), 25) || ''
										}
										four={RavenNumberFormat(chi?.amount)}
										five={<DateFragmentBox date={chi?.created_at} />}
										six={
											<div className="status_and_modal_holder">
												<BadgeComponent
													text={`${
														String(chi?.status) === '1'
															? 'Pending'
															: String(chi?.status) === '2'
															? 'Failed'
															: String(chi?.status) === '3'
															? 'Success'
															: 'Pending'
													}`}
													className={`${
														String(chi?.status) === '1'
															? 'pending'
															: String(chi?.status) === '2'
															? 'failed'
															: String(chi?.status) === '3'
															? 'success'
															: 'pending'
													}`}
												/>
												<div onClick={(e) => {}}>
													{/* <DropMore
                        idx={idx}
                        className={isDropped(idx) && 'show-drop'}
                        chi={chi}
                      /> */}
												</div>
											</div>
										}
									/>
								))}
							</RavenTable>
						</div>
						{/* table content end */}
					</div>
				</PageLayout>
			</DashboardLayout>

			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_settlements"
				onFinishExport={(param) => {
					// console.log(param);

					// formatExportData(param);
					window.open(param, '_blank')?.focus();
				}}
				loading={loading_fee || loading}
			/>
		</>
	);
};
export default Settlement;
