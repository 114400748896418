import React, { ChangeEvent, useRef, useState } from 'react';
import './styles/index.css';
import { RavenButton, RavenInputField } from '@ravenpay/raven-bank-ui';
// import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { businessCategories } from '../../data/businessCategory';
import { nigerianStates } from '../../data/statesInNigeria';
import { ThreeDots } from 'react-loader-spinner';
// import { RavenInputProps } from '@ravenpay/raven-bank-ui/dist/esm/components/types';
import {
	capitalizeAllLetter,
	filterEmptyColumns,
	formatDateTwo,
	formatNumWithComma,
	reactSelectStyle,
	removeSpace,
	symbol,
} from '../../../../../utils/helper/Helper';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useDarkMode } from '../../../../../hooks/useDarkMode';

const CreateBankboxUser = ({ onComplete }: { onComplete: () => void }) => {
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'personal',
		completed: [],
		label: 'Personal Details',
	});

	// const fileInputRef = useRef<HTMLInputElement>(null);
	// const imgPreviewRef = useRef<HTMLImageElement>(null);
	const { all_fees_no_page, partner_info, loading_fee, banks } = useSelector(
		(state: RootState) => state.bankbox
	);
	const { profile } = useSelector((state: RootState) => state.settings);
	const [formData, setFormData] = React.useState({
		fname: '',
		lname: '',
		bvn: '',
		nin: '',
		email: '',
		phone: '',
		business_category: { value: undefined, label: undefined },
		business_description: '',
		region: { value: undefined, label: undefined },
		business_name: '',
		business_address: '',
		fee_category: { value: undefined, label: undefined } as any,
		bank: { value: undefined, label: undefined } as any,
		bank_code: '',
		account_number: '',
		account_name: '',
		settlement_date: '',
		daily_payout_time: '00:00'
	});

	const { loading } = useSelector((state: RootState) => state.bankbox);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};
	const dispatch = useDispatch();
	const handleSubmit = async () => {
		const val = {
			...formData,
			business_category: formData.business_category.label as unknown as string,
			region: formData.region.label as unknown as string,
			fee_ref: String(formData?.fee_category?.reference),
			bank_name: formData?.bank?.label,
			bank_code: formData?.bank?.value,
			account_number: formData?.account_number,
			account_name: formData?.account_name,
		};
		delete val.fee_category;
		delete val.bank;
		// console.log(filterEmptyColumns(val));
		// console.log(formData);

		// log
		const resp = await dispatch(
			bankboxAPI.createUser(filterEmptyColumns(val) as any)
		);

		if (resp.payload?.status === 'success') {
			await dispatch(
				bankboxAPI.getAllUsers({
					per_page: 20,
					page: 1,
				})
			);
			setFormData((prev) => {
				return {
					...prev,
					fname: '',
					lname: '',
					bvn: '',
					nin: '',
					email: '',
					phone: '',
					business_category: { value: undefined, label: undefined },
					business_description: '',
					region: { value: undefined, label: undefined },
					business_name: '',
					business_address: '',
					fee_category: { value: undefined, label: undefined } as any,
					account_name: "",
					account_number: "",
					bank: "",
					bank_code: "",
					daily_payout_time: "",
					settlement_date: "",
				};
			});
			setStep({
				...step,
				id: 'personal',
			});
			onComplete();
		}
	};

	const verifyIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.01322 2.76406C5.52767 2.72301 6.01607 2.52071 6.40887 2.18597C7.32551 1.40481 8.67371 1.40481 9.59035 2.18597C9.98315 2.52071 10.4715 2.72301 10.986 2.76406C12.1865 2.85987 13.1398 3.81318 13.2356 5.01371C13.2767 5.52816 13.479 6.01655 13.8137 6.40936C14.5949 7.326 14.5949 8.67419 13.8137 9.59084C13.479 9.98364 13.2767 10.472 13.2356 10.9865C13.1398 12.187 12.1865 13.1403 10.986 13.2361C10.4715 13.2772 9.98315 13.4795 9.59035 13.8142C8.67371 14.5954 7.32551 14.5954 6.40887 13.8142C6.01607 13.4795 5.52767 13.2772 5.01322 13.2361C3.81269 13.1403 2.85938 12.187 2.76357 10.9865C2.72252 10.472 2.52022 9.98364 2.18548 9.59084C1.40432 8.67419 1.40432 7.326 2.18548 6.40936C2.52022 6.01655 2.72252 5.52816 2.76357 5.01371C2.85938 3.81318 3.81269 2.85987 5.01322 2.76406ZM10.9653 6.96578C11.2777 6.65336 11.2777 6.14683 10.9653 5.83441C10.6529 5.52199 10.1463 5.52199 9.83392 5.83441L7.19961 8.46873L6.16529 7.43441C5.85288 7.12199 5.34634 7.12199 5.03392 7.43441C4.7215 7.74683 4.7215 8.25336 5.03392 8.56578L6.63392 10.1658C6.94634 10.4782 7.45288 10.4782 7.76529 10.1658L10.9653 6.96578Z"
				fill="#EA872D"
			/>
		</svg>
	);

	// const brandColors = ['0B8376', '0B8376', 'EA872D', 'B9DEFE'];

	function disabledAlgo() {
		let bool: boolean = false;
		if (step.id === 'personal') {
			if (
				!validateEmail(formData.email) ||
				!formData.lname ||
				!formData.fname ||
				!formData.phone
				//  ||
				// formData.bvn.length !== 11 ||
				// formData.nin.length !== 11
			) {
				bool = true;
			} else {
				bool = false;
			}
		}

		if (step.id === 'business') {
			if (
				!formData.business_name ||
				!formData.business_category ||
				!formData.business_description
			) {
				bool = true;
			} else {
				bool = false;
			}
		}

		if (step.id === 'address') {
			if (!formData.business_address || !formData.region) {
				bool = true;
			} else {
				bool = false;
			}
		}
		if (step.id === 'fees') {
			if (!formData.fee_category?.value) {
				bool = true;
			} else {
				bool = false;
			}
		}
		if (step.id === 'settlement') {
			if (
				!formData.bank_code ||
				!formData?.account_name ||
				!formData?.account_number || !formData?.daily_payout_time
			) {
				bool = true;
			} else {
				bool = false;
			}
		}
		return bool;
	}

	const formatFeeCategory = () => {
		// console.log(all_fees_no_page);

		if (all_fees_no_page?.length > 0) {
			const newList = all_fees_no_page?.map((chi: any) => {
				return { ...chi, label: chi?.reference, value: chi?.id };
			});
			return newList;
		}
	};

	const [loadingValidate, setLoadingValidate] = useState(false);
	const validateBankDetails = async (e: any) => {
		const payload = {
			bank_code: e || formData?.bank.value,
			account_number: removeSpace(formData?.account_number),
		};
		if (payload?.bank_code && payload?.account_number?.length > 9) {
			setLoadingValidate(true);
			const data = await dispatch(bankboxAPI.getBankboxBankValidate(payload));
			// console.log(data);
			if (data?.payload?.status === 'success') {
				setLoadingValidate(false);
				setFormData((prev) => {
					return {
						...prev,
						account_name: data?.payload?.data.data,
						bank_code: formData?.bank.value,
					};
				});
			} else {
				setLoadingValidate(false);
			}
		}
	};

	React.useEffect(() => {
		if (formData?.bank && formData?.account_number?.length > 9) {
			validateBankDetails(formData?.bank?.value);
		}
	}, [formData?.account_number || formData?.bank]);

	React.useEffect(() => {
		if (banks?.length < 1) {
			dispatch(bankboxAPI.getBankboxBanks({}));
		}
	}, []);

	return (
		<div
			// onClick={() =>
			// 	setStep({
			// 		id: 'settlement',
			// 		label: 'Account settlement',
			// 		completed: ['personal', 'business', 'address', 'fees'],
			// 	})
			// }
			className="bankbox-modals "
			// style={{ minHeight: '70rem' }}
		>
			<div className="bankbox-modals__left">
				<div className="bankbox-modals__left--bg-absolute"></div>
				<div className="bankbox-modals__left--stepper">
					<div
						onClick={() =>
							setStep({
								...step,
								id: 'personal',
							})
						}
						className={`stepper-item ${'active'} ${
							step.completed.includes('personal') && 'completed'
						}`}
					>
						<span
							onClick={() =>
								setStep({
									...step,
									id: 'personal',
								})
							}
						>
							<p>1</p>
						</span>
						<h6>Personal Details</h6>
					</div>

					<div
						onClick={() => {
							step.completed.includes('business') &&
								'completed' &&
								setStep({
									...step,
									id: 'business',
								});
						}}
						className={`stepper-item ${
							(step.id === 'business' || step.id === 'address') && 'active'
						} ${step.completed.includes('business') && 'completed'}`}
					>
						<span>
							<p>2</p>
						</span>
						<h6>Business Details</h6>
					</div>

					<div
						onClick={() => {
							step.completed.includes('address') &&
								'completed' &&
								setStep({
									...step,
									id: 'address',
								});
						}}
						className={`stepper-item ${step.id === 'address' && 'active'} ${
							step.completed.includes('address') && 'completed'
						}`}
					>
						<span>
							<p>3</p>
						</span>
						<h6>Address Details</h6>
					</div>
					<div
						onClick={() => {
							step.completed.includes('fees') &&
								'completed' &&
								setStep({
									...step,
									id: 'fees',
								});
						}}
						className={`stepper-item ${step.id === 'fees' && 'active'} ${
							step.completed.includes('fees') && 'completed'
						}`}
					>
						<span>
							<p>4</p>
						</span>
						<h6>Fee Category</h6>
					</div>
					<div
						onClick={() => {
							step.completed.includes('settlement') &&
								'completed' &&
								setStep({
									...step,
									id: 'settlement',
								});
						}}
						className={`stepper-item ${step.id === 'settlement' && 'active'} ${
							step.completed.includes('settlement') && 'completed'
						}`}
					>
						<span>
							<p>5</p>
						</span>
						<h6>Account Settlement</h6>
					</div>
				</div>
			</div>

			<div className="bankbox-modals__right">
				{step.id === 'personal' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create a Merchant</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<div className="price-content__input-contain--grouped">
								<RavenInputField
									name="fname"
									onChange={handleChange}
									placeholder="Enter First Name"
									label="First Name"
									value={formData.fname}
									color="deep-green-light"
								/>
								<RavenInputField
									name="lname"
									onChange={handleChange}
									placeholder="Enter Last Name"
									value={formData.lname}
									label="Last Name"
									color="deep-green-light"
								/>
							</div>

							<RavenInputField
								name="email"
								onChange={handleChange}
								placeholder="Enter Email Address"
								label="Email Address"
								value={formData.email}
								type="email"
								color="deep-green-light"
							/>

							<RavenInputField
								name="phone"
								onChange={handleChange}
								label="Enter Phone No."
								value={formData.phone}
								placeholder="e.g 903 xxx xxx"
								type="phone"
								color="deep-green-light"
							/>

							{/* veri type start */}
							{partner_info?.partner_type === 'bank' && (
								<>
									{' '}
									<RavenInputField
										name="bvn"
										onChange={(e: { target: { value: string | any[] } }) => {
											if (e.target.value.length < 12) {
												handleChange(e);
											}
										}}
										placeholder="Enter BVN"
										label="BVN"
										value={formData.bvn}
										maxLength={11}
										max={11}
										maxSize={11}
										type="account-number"
										color="deep-green-light"
									/>
									<RavenInputField
										name="nin"
										placeholder="Enter NIN"
										value={formData.nin}
										onChange={(e: { target: { value: string | any[] } }) => {
											if (e.target.value.length < 12) {
												handleChange(e);
											}
										}}
										maxLength={11}
										max={11}
										maxSize={11}
										// pattern={'^[0-9a-f]'}
										label="NIN"
										type="text"
										color="deep-green-light"
									/>
								</>
							)}
							{/* veri type end */}
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() =>
									setStep({
										id: 'business',
										label: 'Business Details',
										completed: ['personal, business'],
									})
								}
								disabled={disabledAlgo()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'business' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create Merchant</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="business_name"
								onChange={handleChange}
								value={capitalizeAllLetter(formData.business_name)}
								placeholder="Business Name"
								label="Business Name"
								type="text"
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_category"
								onChange={(e: any) => {
									setFormData({
										...formData,
										business_category: e,
									});
								}}
								placeholder="Select a Category"
								label="Business Category"
								selectStyles={reactSelectStyle}
								value={formData.business_category}
								type="select"
								selectOption={businessCategories}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_description"
								placeholder="Describe your business"
								onChange={handleChange}
								value={formData.business_description}
								label="Business Description"
								type="textarea"
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() => {
									setStep({
										id: 'address',
										label: 'Address Details',
										completed: ['personal', 'business'],
									});
								}}
								disabled={disabledAlgo()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'address' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create User</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="region"
								placeholder="e.g 'Abia State'"
								label="Region"
								value={{
									value: formData.region.value ?? null,
									label: formData.region.label ?? null,
								}}
								type="select"
								selectStyles={reactSelectStyle}
								onChange={(e: { label: any }) => {
									handleChange({
										target: {
											name: 'region',
											value: e,
										},
									});
								}}
								selectOption={nigerianStates}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_address"
								placeholder="Write your address here"
								label="Address* "
								type="text"
								onChange={handleChange}
								value={formData.business_address}
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								// onClick={handleSubmit}
								onClick={() =>
									setStep({
										id: 'fees',
										label: 'Fees',
										completed: ['personal', 'business', 'address'],
									})
								}
								color="deep-green-light"
								label="Continue"
								disabled={disabledAlgo()}
								loading={loading}
								loaderText="Creating User..."
							/>{' '}
						</div>
					</div>
				)}
				{step.id === 'fees' && (
					<>
						<div className="bankbox-modals__right--content">
							<div className="basic-content__title">
								<h5>Create User</h5>
								<h6>{step.label}</h6>
							</div>

							<div className="price-content__input-contain">
								<RavenInputField
									type="select"
									color="deep-green-light"
									value={
										all_fees_no_page?.length === 1
											? {
													label: all_fees_no_page[0]?.reference,
													value: all_fees_no_page[0]?.id,
											  }
											: formData?.fee_category
									}
									label="Fee Category"
									style={{ position: 'relative', zIndex: 10 }}
									placeholder="Select Fee Category"
									name="fee_category"
									selectStyles={reactSelectStyle}
									selectOption={formatFeeCategory()}
									onChange={(e: any) => {
										setFormData((prev) => {
											return { ...prev, fee_category: e };
										});
									}}
								/>
							</div>
							<div className="price-content__selected-more-details-box border-theme">
								{/* value label box start */}
								<div className="label-boxes border-theme-right">
									<p className="label border-theme-bottom grey-white-color">
										Rate
									</p>
									<p className="label border-theme-bottom grey-white-color">
										Cap
									</p>
									<p className="label border-theme-bottom grey-white-color">
										Trf Fee:
									</p>
									<p className="label border-theme-bottom grey-white-color">
										Collection Fee:
									</p>
								</div>
								{/* value label box end */}
								{/* value boxes start */}
								<div className="value-boxes">
									<p className="value border-theme-bottom">
										{formData?.fee_category?.fee ||
										all_fees_no_page?.length >= 1 ? (
											`${
												all_fees_no_page?.length === 1
													? all_fees_no_page[0]?.fee
													: formData?.fee_category?.fee || ''
											}${'%'}`
										) : (
											<>&nbsp;</>
										)}
									</p>
									<p className="value border-theme-bottom">
										{formData?.fee_category?.cap ||
										all_fees_no_page?.length >= 1 ? (
											`${
												symbol('ngn') +
												formatNumWithComma(
													all_fees_no_page?.length === 1
														? all_fees_no_page[0]?.cap
														: formData?.fee_category?.cap,
													'ngn'
												)
											}`
										) : (
											<>&nbsp;</>
										)}
									</p>
									<p className="value border-theme-bottom">
										{' '}
										{formData?.fee_category?.transfer_fee ||
										all_fees_no_page?.length >= 1 ? (
											`${
												symbol('ngn') +
												formatNumWithComma(
													all_fees_no_page?.length === 1
														? all_fees_no_page[0]?.transfer_fee
														: formData?.fee_category?.transfer_fee,
													'ngn'
												)
											}`
										) : (
											<>&nbsp;</>
										)}
									</p>
									<p className="value border-theme-bottom">
										{' '}
										{formData?.fee_category?.collection_fee ||
										all_fees_no_page?.length >= 1 ? (
											`${
												symbol('ngn') +
												formatNumWithComma(
													all_fees_no_page?.length === 1
														? all_fees_no_page[0]?.bank_collection_fee
														: formData?.fee_category?.bank_collection_fee,
													'ngn'
												)
											}`
										) : (
											<>&nbsp;</>
										)}
									</p>
								</div>
								{/* value boxes end */}
							</div>
							<div className="price-content__form-submit">
								<RavenButton
									onClick={() =>
										setStep({
											id: 'settlement',
											label: 'Account settlement',
											completed: ['personal', 'business', 'address', 'fees'],
										})
									}
									color="deep-green-light"
									label="Continue"
									disabled={disabledAlgo()}
									// loading={loading}
									// loaderText="Creating User..."
								/>{' '}
							</div>
						</div>
					</>
				)}
				{step.id === 'settlement' && (
					<>
						<div className="bankbox-modals__right--content">
							<div className="basic-content__title">
								<h5>Create User</h5>
								<h6>{step.label}</h6>
							</div>

							<div className="price-content__input-contain">
								<RavenInputField
									type="select"
									color="deep-green-light"
									value={formData?.bank}
									selectStyles={reactSelectStyle}
									label="Select Bank"
									style={{ position: 'relative', zIndex: 100 }}
									placeholder="Select Bank"
									name="bank"
									selectOption={banks}
									onChange={(e: any) => {
										setFormData((prev) => {
											return {
												...prev,
												bank: e,
												account_name: '',
												account_number: '',
											};
										});
									}}
									// selectMenuOpen={true}
								/>
								<RavenInputField
									type={`account-number`}
									style={{ marginBottom: '-1.5rem' }}
									maxLength={10}
									color="deep-green-light"
									value={formData?.account_number}
									label="Account Number"
									onChange={handleChange}
									placeholder="E.g 022...."
									name="account_number"
									labelSpanText={
										(
											<>
												{loadingValidate ? (
													<div
														style={{
															padding: '0rem',
															display: 'flex',
															justifyContent: 'flex-end',
															transform: 'translateX(1.5rem)',
														}}
														className="load-wrap"
													>
														<ThreeDots
															height="10"
															width="80"
															radius="9"
															color={theme === 'light' ? '#020202' : '#ffffff'}
															ariaLabel="three-dots-loading"
															wrapperStyle={{}}
															// wrapperClassName=""
															visible={true}
														/>
													</div>
												) : (
													'Verify'
												)}
											</>
										) as any
									}
								/>
								{formData?.account_name && (
									<BadgeComponent
										text={formData?.account_name}
										className="Pending"
									>
										{verifyIcon}
									</BadgeComponent>
								)}

								{/* settlement day start */}
								<form
									action=""
									onSubmit={(e) => {
										e.preventDefault();
									}}
									autoComplete="off"
									className=""
									style={{ width: '100%', marginTop: '2rem' }}
								>
									{' '}
									<RavenInputField
										label="Daily Payout Time"
										color="deep-green-light"
										type="date"
										placeholder="Enter time"
										dateOptions={{
											enableTime: true,
											noCalendar: true,
											dateFormat: 'H:i',
											position: "above",
										}}
										value={formData?.daily_payout_time}
										onChange={(e: any) => {
											setFormData((prev) => {
												return {
													...prev,
													daily_payout_time: formatDateTwo(e)?.time,
												};
											});
										}}
									/>
								</form>

								{/* settlement day end */}
							</div>
							<div className="price-content__form-submit">
								<RavenButton
									onClick={handleSubmit}
									color="deep-green-light"
									label="Create Merchant"
									disabled={disabledAlgo()}
									loading={loading}
									loaderText="Creating User..."
								/>{' '}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CreateBankboxUser;
