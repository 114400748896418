import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import '../styles/AlertReceivers.css';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { useDispatch } from 'react-redux';
import { hasSpecialCharacters } from '../../../../../utils/helper/Helper';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
}

const ManageInactiveModal: React.FC<MyComponentProps> = ({
	visible,
	onClose,
}) => {
	type detailProp = {
		days: string;
	};
	const dispatch = useDispatch();
	const [details, setDetails] = useState<detailProp>({
		days: '12',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const [sendLoading, setSendLoading] = useState(false);
	const handlSubmit = async () => {
		setSendLoading(true);
		const data = await dispatch(
			bankboxAPI.getAllBankBox({
				per_page: 20,
				page: 1,
				set_terminal_inactive_days: String(details?.days),
			})
		);

		if (data?.payload?.status === 'success') {
			setSendLoading(false);
			onClose && onClose();
		} else {
			setSendLoading(false);
		}
		// const obj = {
		// 	set_terminal_inactive_days: String(details?.days),
		// };

		// setSendLoading(true);
		// const data = await dispatch(
		// 	bankboxAPI.changeTerminalActiveDays(obj as any)
		// );
		// console.log(data);
		// if (data?.payload?.status === 'success') {
		// 	dispatch(
		// 		bankboxAPI.getAllBankBox({
		// 			per_page: 20,
		// 			page: 1,
		//       set_terminal_inactive_days:  String(details?.days),
		// 		})
		// 	);
		// 	setSendLoading(false);
		// 	onClose && onClose();
		// } else {
		// 	setSendLoading(false);
		// }
	};

	return (
		<RavenModal
			onBtnClick={handlSubmit}
			btnLabel="Save Changes"
			btnColor="deep-green-light"
			visble={visible}
			onClose={onClose}
			className={`session-expired-wrap-modal`}
			disabled={!details?.days}
			loading={sendLoading}
		>
			<div className="alert-receiver-modal-wrap-index">
				<div className="title-text-box">
					<p className="title">Configure Inactive Duration</p>
					<p className="text grey-white-color">
						Set your inactive number of days for terminals
					</p>
				</div>
				<div className="receiver-list-box">
					<RavenInputField
						color="deep-green-light"
						type="number"
						placeholder="Enter days "
						label="Days of In-activity"
						value={details?.days}
						name="days"
						// onChange={handleChange}
						onChange={(e?: any) => {
							if (hasSpecialCharacters(e.target.value)) {
								return;
							}
							handleChange(e);
						}}
					/>

					<div className="info-span-box">
						<p className="info grey-bg-two">
							This would mean a POS terminal is considered <br /> inactive if it
							has no recorded transactions <br /> within the past{' '}
							<span>{`${
								details?.days ? `${details?.days} days` : `saved days` || ''
							}`}</span>
							.
						</p>
					</div>
				</div>
			</div>
		</RavenModal>
	);
};

export default ManageInactiveModal;
