import { RavenModal, RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import { FaRegEye } from 'react-icons/fa';
import { icons } from '../../../../../assets/icons';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import SettleMentAccountModal from '../../users/component/SettleMentAccountModal';
import ComplianceLayout from '../ComplianceLayout';

const ComplianceNinVerification = () => {
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	type showModalProp = {
		create: boolean;
		export: boolean;
		view_details: boolean;
		edit_details: boolean;
	};

	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		view_details: false,
		edit_details: false,
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	function isDropped(idx: number | string) {
		return showDrop.id === idx && showDrop.show;
	}

	const editIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const DropMore = ({ className, idx, chi }: any) => {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							view_fee: true,
						}));
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Details</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							edit_fee: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{editIcon}</figure>
					<p>Edit Details</p>
				</div>
			</div>
		);
	};

	// Dummy data for all_fees
	const all_fees = {
		data: [
			{
				email: 'emmy4sure.web@gmail.com',
				name: 'Folasayo Ogunnaike',
				moblie_number: '070 1526 3711',
				NIN: 12345678903,
				Status: 0,
				created_at: '2024-11-01T00:00:00',
			},
			{
				email: 'emmy4sure.web@gmail.com',
				name: 'Yussuf Ahmed',
				moblie_number: '070 1526 3711',
				NIN: 12345678903,
				Status: 1,
				created_at: '2024-11-10T00:00:00',
			},
			{
				email: 'emmy4sure.web@gmail.com',
				name: 'Josh Michael',
				moblie_number: '070 1526 3711',
				NIN: 12345678903,
				Status: 0,
				created_at: '2024-11-15T00:00:00',
			},
			{
				email: 'emmy4sure.web@gmail.com',
				name: 'Hannah Pedro',
				moblie_number: '070 1526 3711',
				NIN: 12345678903,
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				email: 'emmy4sure.web@gmail.com',
				name: 'Esther Joel',
				moblie_number: '070 1526 3711',
				NIN: 12345678903,
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				email: 'emmy4sure.web@gmail.com',
				name: 'Ebubechukwu Agnes',
				moblie_number: '070 1526 3711',
				NIN: 12345678903,
				Status: 0,
				created_at: '2024-11-15T00:00:00',
			},
		],
	};

	return (
		<>
			{/* Table content start */}
			<ComplianceLayout>
				<div className="transaction__table non-mobile">
					<RavenTable
						action={false}
						className="table__main"
						headerList={[
							'FULLNAME',
							'EMAIL ADDRESS',
							'MOBILE NUMBER',
							'NIN',
							'DATE LOGGED',
							'STATUS',
							'',
						]}
					>
						{all_fees?.data?.map((chi: any, idx: any) => {
							const { name, moblie_number, email, NIN, Status } = chi || {};
							return (
								<RavenTableRow
									key={idx}
									one={
										capitalizeFirstWord(formatTypeFunction(name) as any) ||
										'----'
									}
									two={email || '----'}
									three={moblie_number || '----'}
									four={NIN || '----'}
									five={<DateFragmentBox date={chi?.created_at} />}
									six={
										<>
											{/* Render badges based on the Status */}
											<BadgeComponent
												className={Status === 1 ? 'success' : 'warning'}
												text={Status === 1 ? 'verified' : 'pending'}
											/>
										</>
									}
									seven={
										<>
											<div
												style={{ position: 'relative' }}
												onClick={(e) => {
													e.stopPropagation();
													setShowDrop((prev) => ({
														...prev,
														id: idx,
														show: showDrop?.id === idx ? !showDrop.show : true,
													}));
													openModal();
												}}
											>
												<DropMore
													idx={idx}
													className={isDropped(idx) ? 'show-drop' : ''}
													chi={chi}
												/>
												{icons.three_dots}
											</div>
										</>
									}
								/>
							);
						})}
					</RavenTable>
				</div>
			</ComplianceLayout>

			{/* Table content end */}
		</>
	);
};

export default ComplianceNinVerification;
