import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useEffect } from 'react';
import { icons } from '../../../assets/icons';
import './style/index.css';
import {
	exportAirtime,
	exportBetting,
	exportCable,
	exportCollections,
	exportCustomers,
	exportData,
	exportElectricity,
	exportTransactions,
	exportTransfers,
	exportVirtualAccount,
} from '../../../redux/export';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
	capitalizeFirstLetter,
	formatDate,
} from '../../../utils/helper/Helper';
import { logger } from '../../../services/logger';
import { bankboxAPI } from '../../../redux/bankbox';
import { useLocation } from 'react-router-dom';

const days = [
	// 'All',
	'Today',
	'Last 7 Days',
	'Last 14 Days',
	'Last 30 Days',
	'Custom',
];

const ExportModal = ({
	visible,
	onClose,
	onClick,
	onChange,
	value,
	page = 'wallet',
	pageTitle,
	buttonText,
	onFinishExport,
}: {
	visible: boolean;
	onClose?: () => void;
	onClick?: () => void;
	page?: string;
	buttonText?: string;
	pageTitle?: string;
	loading?: boolean;
	value?: {
		date:
			| string
			| undefined
			| {
					start_date?: string;
					end_date?: string;
			  };
		fileType: string;
	};
	onChange?: any;
	onFinishExport?: (param?: any) => void;
}) => {
	const [selectedDay, setSelectedDay] = React.useState<
		| string
		| undefined
		| {
				start_date?: string | undefined;
				end_date?: string | undefined;
		  }
	>('All');
	const [fileType, setFileType] = React.useState('csv');
	const [loading, setLoading] = React.useState(false);
	const [dateRange, setDateRange] = React.useState<{
		start_date?: string | undefined;
		end_date?: string | undefined;
	}>({
		start_date: '2022-06-01',
		end_date: formatDate(addDays(new Date(), 1)),
	});
	const getDatesInRange = (daysAgo: number) => {
		var dates = [] as any;
		var currentDate = new Date();
		for (var i = 0; i < daysAgo; i++) {
			var date = new Date(currentDate);
			date.setDate(currentDate.getDate() - i);
			dates.push(date.toISOString().slice(0, 10)); // Format date as "YYYY-MM-DD"
		}
		const obj = {
			start: dates[0],
			end: dates[dates?.length - 1],
		};

		return obj;
	};
	const dispatch = useDispatch<AppDispatch>();
	function getNthDaysAgo(d: number) {
		const today = new Date(); // Get today's date
		const nthDay = new Date(today); // Create a new date object

		nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

		return nthDay;
	}

	function downloadFile(url: any, filename: string) {
		const a = document.createElement('a');
		// console.log(url);

		// logger.log(url.url ?? url, 'oinu');
		a.href = url.url ?? url;
		a.download = filename || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				removeEventListener('click', clickHandler);
			}, 150);
		};

		a.addEventListener('click', clickHandler, false);

		a.click();

		return a;
	}

	function handleFilter(str?: string) {
		const seventhDayAgo = getNthDaysAgo(7);
		const last30Days = getNthDaysAgo(30);
		const location = useLocation();
		const formated30days = formatDate(last30Days);
		const formated7days = formatDate(seventhDayAgo);
		const formated14days = formatDate(getNthDaysAgo(14));

		setDateRange({
			start_date:
				str === 'All'
					? formatDate(new Date('2022-06-01'))
					: str === 'Today'
					? formatDate(new Date())
					: str === 'Last 7 Days'
					? formated7days
					: str === 'Last 14 Days'
					? formated14days
					: str === 'Last 30 Days'
					? formated30days
					: dateRange.start_date,
			end_date:
				str === 'All'
					? formatDate(addDays(new Date(), 1))
					: str === 'Today'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 7 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 14 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 30 Days'
					? formatDate(addDays(new Date(), 1))
					: dateRange.end_date,
		});
	}

	async function handleExport() {
		setLoading(true);
		// handleFilter(selectedDay as string);
		let resp;
		// return
		if (page === 'wallet' || page === 'transactions') {
			resp = await dispatch(
				exportTransactions({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'collections') {
			resp = await dispatch(
				exportCollections({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'transfers') {
			resp = await dispatch(
				exportTransfers({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'customers') {
			resp = await dispatch(
				exportCustomers({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'airtime') {
			resp = await dispatch(
				exportAirtime({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'data') {
			resp = await dispatch(
				exportData({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}
		if (page === 'bankbox_users') {
			resp = await dispatch(
				bankboxAPI.exportUsers({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					stop_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					// file_type: fileType,
				})
			);
		}

		if (page === 'disputes') {
			resp = await dispatch(
				bankboxAPI.exportDisputes({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					stop_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					// file_type: fileType,
				})
			);
		}

		if (page === 'fees') {
			let val;
			// const new

			if (selectedDay === 'Today') {
				const today = new Date();
				const year = today.getFullYear();
				const month = today.getMonth() + 1; // Months are zero-based
				const day = today.getDate();

				const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
					day < 10 ? '0' + day : day
				}`;
				const obj = {
					exportType: fileType,
					start_date: formattedDate,
					stop_date: formattedDate,
				};
				val = obj;
			}
			if (selectedDay !== 'Today' && selectedDay !== 'Custom') {
				// getDatesInRange(7);
				const obj = {
					exportType: fileType,
					// start_date: getDatesInRange(
					// 	selectedDay === 'Last 7 Days'
					// 		? 7
					// 		: selectedDay === 'Last 14 Days'
					// 		? 14
					// 		: 30
					// )?.end,
					// stop_date: getDatesInRange(
					// 	selectedDay === 'Last 7 Days'
					// 		? 7
					// 		: selectedDay === 'Last 14 Days'
					// 		? 14
					// 		: 30
					// )?.start,
					start_date: '',
					stop_date: '',
					range:
						selectedDay === 'Last 7 Days'
							? 7
							: selectedDay === 'Last 14 Days'
							? 14
							: 30,
				};
				val = obj;
			} else {
				// getDatesInRange(7);
				const obj = {
					exportType: fileType,
					start_date: dateRange?.start_date?.replaceAll('/', '-'),
					stop_date: dateRange?.end_date?.replaceAll('/', '-'),
				};
				val = obj;
			}
			resp = await dispatch(bankboxAPI.exportFees(val));
		}

		if (page === 'bankbox_transactions') {
			let val;
			// const new

			if (selectedDay === 'Today') {
				const today = new Date();
				const year = today.getFullYear();
				const month = today.getMonth() + 1; // Months are zero-based
				const day = today.getDate();

				const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
					day < 10 ? '0' + day : day
				}`;
				const obj = {
					exportType: fileType,
					start_date: formattedDate,
					stop_date: formattedDate,
				};
				val = obj;
			}
			if (selectedDay !== 'Today' && selectedDay !== 'Custom') {
				// getDatesInRange(7);
				const obj = {
					exportType: fileType,
					start_date: '',
					stop_date: '',
					range:
						selectedDay === 'Last 7 Days'
							? 7
							: selectedDay === 'Last 14 Days'
							? 14
							: 30,
				};
				val = obj;
			} else {
				// getDatesInRange(7);
				const obj = {
					exportType: fileType,
					start_date: dateRange?.start_date?.replaceAll('/', '-'),
					stop_date: dateRange?.end_date?.replaceAll('/', '-'),
				};
				val = obj;
			}
			// console.log(val);
			resp = await dispatch(bankboxAPI.exportBankboxTransaction(val));
			// log
		}
		if (page === 'bankbox_settlements') {
			let val;
			// const new

			if (selectedDay === 'Today') {
				const today = new Date();
				const year = today.getFullYear();
				const month = today.getMonth() + 1; // Months are zero-based
				const day = today.getDate();

				const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
					day < 10 ? '0' + day : day
				}`;
				const obj = {
					exportType: fileType,
					start_date: formattedDate,
					stop_date: formattedDate,
				};
				val = obj;
			}
			if (selectedDay !== 'Today' && selectedDay !== 'Custom') {
				// getDatesInRange(7);
				const obj = {
					exportType: fileType,
					start_date: '',
					stop_date: '',
					range:
						selectedDay === 'Last 7 Days'
							? 7
							: selectedDay === 'Last 14 Days'
							? 14
							: 30,
				};
				val = obj;
			} else {
				// getDatesInRange(7);
				const obj = {
					exportType: fileType,
					start_date: dateRange?.start_date?.replaceAll('/', '-'),
					stop_date: dateRange?.end_date?.replaceAll('/', '-'),
				};
				val = obj;
			}
			// console.log(val);
			resp = await dispatch(bankboxAPI.exportBankboxSettlement(val));
			// log
		}
		if (page === 'bankbox_terminals') {
			resp = await dispatch(
				bankboxAPI.exportBankboxes({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					stop_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					// file_type: fileType,
				})
			);
		}

		if (page === 'electricity') {
			resp = await dispatch(
				exportElectricity({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'betting') {
			resp = await dispatch(
				exportBetting({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'cable') {
			resp = await dispatch(
				exportCable({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'virtual-accounts') {
			resp = await dispatch(
				exportVirtualAccount({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}
		// logger.log(resp, 'from here');

		if (resp?.payload?.status === 'success') {
			// console.log(resp);

			setLoading(false);
			onFinishExport && onFinishExport(resp?.payload?.data?.data);
			onClose && onClose();
		} else {
			setLoading(false);
		}
	}

	function addDays(date: Date | string, days: number) {
		// Function to add Days
		var result = new Date(date);
		result.setDate(result.getDate() + days);

		return result;
	}

	return (
		<RavenModal
			visble={visible}
			onClose={onClose}
			loading={loading}
			disabled={!fileType || !dateRange}
			btnLabel={buttonText || 'Export Data'}
			btnColor="deep-green-light"
			// onBtnClick={() => {console.log("jhg");
			// }}
			className={`session-expired-wrap-modal`}
			onBtnClick={handleExport}
		>
			<div className="export-modal">
				<div className="export-modal__title">
					{pageTitle ? (
						<h5>{pageTitle}</h5>
					) : (
						<h5>
							Export{' '}
							{capitalizeFirstLetter(page)
								.replaceAll('_', ' ')
								.replaceAll('-', ' ')}
						</h5>
					)}
					<p>Choose how you would like to export your data</p>
				</div>

				<div className="export-modal__export-as">
					<label htmlFor="">Export as</label>
					<div className={'export-modal__export-as--selector-wrap'}>
						<div
							onClick={() => {
								setFileType('csv');
								onChange && onChange({ ...value, fileType: 'csv' });
							}}
							className={`selector-wrap__item ${
								fileType === 'csv' && 'selected'
							}`}
						>
							<figure>
								{fileType === 'csv' ? icons.radio_check : icons.radio_unchecked}
							</figure>
							<p>CSV File</p>
						</div>
						<div
							// onClick={() => {
							// 	setFileType('excel');
							// 	onChange && onChange({ ...value, fileType: 'excel' });
							// }}
							style={{ cursor: 'not-allowed', opacity: '.65' }}
							className={`selector-wrap__item ${
								fileType === 'excel' && 'selected'
							}`}
						>
							<figure>
								{fileType === 'excel'
									? icons.radio_check
									: icons.radio_unchecked}
							</figure>
							<p>Excel File</p>
						</div>
					</div>

					<div className="export-modal__days-selector">
						<label htmlFor="">Date</label>

						<div className={`days-selector__item-wrap `}>
							{days.map((d, i) => {
								return (
									<div
										onClick={() => {
											// console.log(d);

											setSelectedDay(d);
											// handleFilter(d as string);
											onChange && onChange({ ...value, date: d });
										}}
										key={i}
										className={`days-selector__item ${
											selectedDay === d && 'selected'
										}`}
									>
										<p>{d}</p>
										{selectedDay === d && 'selected' && (
											<figure>{icons.green_checkmark}</figure>
										)}
									</div>
								);
							})}
						</div>
					</div>

					<div
						className={`export-modal__custom-date ${
							selectedDay === 'Custom' && 'show'
						}`}
					>
						<RavenInputField
							color="deep-green-dark"
							label="From"
							placeholder="Select a Date"
							type="date"
							onChange={(e: string) => {
								// console.log(e);

								setDateRange({
									...(dateRange as {
										start_date: string | undefined;
										end_date: string | undefined;
									}),
									start_date: formatDate(new Date(e)),
								});
							}}
						/>
						<RavenInputField
							color="deep-green-dark"
							label="To"
							placeholder="Select a Date"
							type="date"
							onChange={(e: string) =>
								setDateRange({
									...(dateRange as {
										start_date: string | undefined;
										end_date: string | undefined;
									}),
									end_date: formatDate(new Date(e)),
								})
							}
						/>
					</div>
				</div>
			</div>
		</RavenModal>
	);
};

export default ExportModal;
