import { RavenModal } from "@ravenpay/raven-bank-ui"
import "./styles/billsModalIndex.css"
type ButtonColors =
  | "purple-light"
  | "error-light"
  | "info-right"
  | "white-light"
  | "blue-light"
  | "green-light"
  | "orange-light"
  | "black-light"
  | "deep-green"
  | "deep-green-light"
  | "error-dark"
  | "deep-green-dark";
  // |"#F7F7F7";

interface BillsModalProps  {
direction?: string;
amount?: number;
businessEmail?: string;
type?: string;
fee?: number;
mobileNumber?: string;
provider?: string;
references?: string
merchantRef?: string;
status?: string;
date?: string;
plan?: string;
token?: string;
meterNumber?: string;
smartCard?: string;
userId?: string;
title?: string;
visible?: boolean;
onClose?: () => void;
btnLabel?: string;
btnColor?: ButtonColors;
singleChildren?:any;
}



const BillsModal: React.FC<BillsModalProps> = ({
direction,
amount,
businessEmail,
type,
fee,
mobileNumber,
provider,
references,
merchantRef,
status,
date,
plan,
token,
meterNumber,
smartCard,
userId,
title,
visible,
onClose,
btnColor,
btnLabel

}
) => {


  const renderContentRow = (label: string, value?: string | number) => {
    if (!value) return null; // Skip rendering if value is not provided
    return (
      <div className="bills_details_modal_contents_content_holder">
        <p className="bills_details_modal_contents_content_head">{label}</p>
        <p className="bills_details_modal_contents_content_details">{value}</p>
      </div>
    );
  };
  return(
  <RavenModal 
  onBtnClick={() => {}}
  visble={visible}
  onClose={onClose}
  btnColor={btnColor}
  btnLabel={btnLabel}
>
    <div className="bills_modal_contents_content_holder_wrap">
      <p className="bills_details_modal_header">{title}</p>
      <div className="bills_details_modal_contents_content_holder_wrap">

      {renderContentRow('Direction', direction)}
        {renderContentRow('Amount', amount)}
        {renderContentRow('Business Email', businessEmail)}
        {renderContentRow('Type', type)}
        {renderContentRow('Fee', fee)}
        {renderContentRow('Mobile No', mobileNumber)}
        {renderContentRow('Provider', provider)}
        {renderContentRow('References', references)}
        {renderContentRow('Merchant Ref', merchantRef)}
        {renderContentRow('Status', status)}
        {renderContentRow('Date', date)}
        {renderContentRow('Plan', plan)}
        {renderContentRow('Token', token)}
        {renderContentRow('Meter Number', meterNumber)}
        {renderContentRow('Smart Card', smartCard)}
        {renderContentRow('User ID', userId)}

      </div>
    </div>
  </RavenModal>
  
)
}
export default BillsModal



// import { RavenModal } from '@ravenpay/raven-bank-ui';
// import React from 'react';
// import ImageFragment from '../../../../../../components/common/ImageFragment';
// import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
// import livecheckmodal from '../../../../../../assets/images/livecheckmodal.png';
// import './styles/billsModalIndex.css';
// interface MyComponentProps {
//   visible?: boolean;
//   onClose?: () => void;
//   singleChild?: any;
//   // style?: React.CSSProperties;
// }
// const BillsModal = ({ onClose, visible, singleChild }: MyComponentProps) => {
//   return (
//     <>
//       <div className="liveness-modal-container">
//         <RavenModal
//           visble={visible}
//           onClose={
//             () => {
//               onClose && onClose();
//             }
//             // setShowModal((prev) => ({ ...prev, view_liveliness: false }))
//           }
//           onBtnClick={() => {
//             console.log('Button inside modal clicked');
//           }}
//           className="liveness-modal-container"
//         >
//           <div>
//             <p>{singleChild.amount}</p>
//           </div>
//           {/* <div className="liveliness-check-modal ">
//             <div className="modal-content">
//               <div className="image-container">
//                 <ImageFragment
//                   url={livecheckmodal}
//                   className="profile-avatar"
//                   styles={{
//                     width: '100%',
//                     height: '50%',
//                   }}
//                 />
//               </div>
//               <div className="details-container">
//                 <h2>Liveliness Check</h2>
//                 <table>
//                   <tbody>
//                     <tr>
//                       <td>
//                         <p>Merchant Name:</p>
//                       </td>
//                       <td>{singleChild.name}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <p>BVN:</p>
//                       </td>
//                       <td>{singleChild.Bvn}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <p>NIN:</p>
//                       </td>
//                       <td>{singleChild.Nin}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <p>Email:</p>
//                       </td>
//                       <td>{singleChild.email}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <p>Status:</p>
//                       </td>
//                       <td>
//                         <BadgeComponent
//                           className={
//                             singleChild.Status === 1 ? 'success' : 'warning'
//                           }
//                           text={
//                             singleChild.Status === 1 ? 'Verified' : 'Pending'
//                           }
//                         />
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <p>Date Authorized:</p>
//                       </td>
//                       <td>
//                         {new Date(singleChild.created_at).toLocaleDateString()}
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div> */}
//         </RavenModal>
//       </div>
//     </>
//   );
// };
// export default BillsModal;