import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import { CSVDownload } from 'react-csv';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
	RavenToolTip,
} from '@ravenpay/raven-bank-ui';
import {
	capitalizeFirstLetter,
	downloadCsvFile,
	formatDate,
	formatDateHelper,
	formatDateTime,
	formatMetaData,
	formatNumWithComma,
	formatNumWithCommaNaira,
	formatNumWithCommaNairaSymbolTwo,
	formatTypeFunction,
	getActionNamesByPolicyName,
	lowerCaseWrap,
	mapHotkey,
	maskCardNumber,
	symbol,
	trimLongString,
} from '../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import { icons } from '../../../../assets/icons';
import Pagination from '../../../../components/common/pagination';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './style/index.css';
import { generateRandomColor } from '../../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';
import { getTransfers } from '../../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import RootState from '../../../../redux/types';
import Skeleton from 'react-loading-skeleton';
import { logger } from '../../../../services/logger';
import useDebounce from '../../../../hooks/UseDebounce';
import { omniSearch } from '../../../../redux/transaction';
import Copy from '../../../../components/common/copyCheck';
import SmartFilter from '../../../../components/common/smartFilter';
import ExportModal from '../../../../components/common/exportModal';
import TransactionModal from '../../../../components/common/transactionModal';
import MobileTableCard from '../../../../components/common/table/mobile/MobileTable';
import { bankboxAPI } from '../../../../redux/bankbox';
import { debounce } from 'lodash';
import TwoDetailView from '../../../../components/common/TwoDetailView';
import BadgeComponent from '../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../components/common/DateFragmentBox';
const BankBoxTransactions = () => {
	const [demo, setDemo] = useState(true);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showConfiguration, setShowConfiguration] = useState<boolean>(false);

	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});
	const [searchQuery, setSearchQuery] = useState('');

	useState<string>('business-account');
	const [boot, onBoot] = useState(false);
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { transfers } = useSelector((state: RootState) => state.wallet);

	const { all_bankbox_trx, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	const { searchLoading } = useSelector(
		(state: RootState) => state.transaction
	);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: string | number, refunded?: number) {
		let s;

		if (status !== '00') {
			s = 'failed';
		}

		if (status === '00') {
			s = 'successful';
		}
		return (
			<div className={`transaction-status ${s && s?.toLowerCase()}`}>
				<p style={{ textTransform: 'capitalize' }}>{s}</p>
			</div>
		);
	}

	//fetch transfers
	useEffect(() => {
		if (!boot && transfers.transfers) {
			onBoot(true);
		}
		!terminalId &&
			dispatch(
				bankboxAPI.getAllTransactions({
					page: currentPage,
					per_page: 20,
				})
			);
	}, [currentPage]);

	const [noDataFound, setNoDataFound] = useState<boolean>(true);

	useEffect(() => {
		if (!boot && transfers.transfers) {
			onBoot(true);
		}
		!terminalId && getAllUserFirst();
	}, [currentPage]);

	const getAllUserFirst = async () => {
		const data = await dispatch(
			bankboxAPI.getAllTransactions({
				page: currentPage,
				per_page: 20,
			})
		);
		if (data?.payload?.status === 'success') {
			// console.log(data);

			const checkLength = data?.payload?.data?.data?.data;
			if (checkLength?.length > 0) {
				setNoDataFound(false);
				// console.log('ooo');
			} else {
				setNoDataFound(true);
			}
		}
	};

	function returnInitial(name: string) {
		const i = name.split(' ');

		if (typeof length !== 'undefined') {
			if (i?.length === 1) {
				return i[0]?.slice(0, 1)?.toUpperCase();
			} else return i[0]?.slice(0, 1)?.toUpperCase();
		}
		{
			return '';
		}
	}

	const debounceSearchTerm = useDebounce(searchQuery, 1000);
	useEffect(() => {
		let isMount = true;
		logger.log('searching');

		if (isMount && debounceSearchTerm?.length > 2) {
			dispatch(omniSearch(searchQuery));
		}

		if (isMount && debounceSearchTerm?.length < 1) {
			dispatch(
				getTransfers({
					page: currentPage,
					limit: 20,
				})
			);
		}

		return () => {
			isMount = false;
		};
	}, [debounceSearchTerm]);
	let trx = all_bankbox_trx?.data;

	function convertToHumanReadable(inputString: string) {
		// Split the input string by underscores
		const words = inputString.split('_');

		// Capitalize the first letter of each word and join them with spaces
		const humanReadableString = words
			.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');

		return humanReadableString;
	}

	const searchParams = new URLSearchParams(window.location.search);
	const terminalId = searchParams.get('serial');

	const shareIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="dull-bright-filter"
				d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const csvHeader = [
		{ label: 's/n', key: 'sn' },
		{ label: 'terminal ID', key: 'id' },
		{ label: 'direction', key: 'direction' },
		{ label: 'system_reference', key: 'system_reference' },
		{ label: 'business_email', key: 'business_email' },
		{ label: 'type', key: 'type' },
		{ label: 'amount', key: 'amount' },
		{ label: 'credited_amount', key: 'credited_amount' },
		{ label: 'merchant_settlement', key: 'merchant_settlement' },
		{ label: 'fee', key: 'fee' },
		{ label: 'platform_fee', key: 'platform_fee' },
		{ label: 'balance_before', key: 'balance_before' },
		{ label: 'balance_after', key: 'balance_after' },
		{ label: 'stamp_duty', key: 'stamp_duty' },
		{ label: 'terminal_label', key: 'terminal_label' },
		{ label: 'transaction_reference', key: 'tranx_reference' },
		{ label: 'transaction_details', key: 'transaction_details' },
		{ label: 'provider', key: 'provider' },
		{ label: 'date', key: 'created_at' },
		{ label: 'status', key: 'status' },
	];
	const formatExportData = (param: any) => {
		if (param?.length > 0) {
			const newData = param?.map((chi: any, idx: any) => {
				return {
					sn: idx + 1 < 10 ? `0${idx + 1}` : idx + 1,
					id: chi?.t_id || 'NIL',
					direction: chi?.direction
						? capitalizeFirstLetter(chi?.direction)
						: 'NIL',
					system_reference: chi?.reference || 'NIL',
					business_email:
						formatMetaData(chi?.pdon_meta)?.terminal?.poseidon_business_email ||
						chi?.business_email ||
						'NIL',
					type:
						chi?.type === 'pdon_card'
							? `card_payment`
							: formatTypeFunction(chi?.type) || 'NIL',
					amount: RavenNumberFormat(chi?.amount) || 'NIL',
					credited_amount: RavenNumberFormat(chi?.credited_amount),
					merchant_settlement: RavenNumberFormat(chi?.merchant_settlement),
					fee: RavenNumberFormat(chi?.fee) || 'NIL',
					platform_fee: RavenNumberFormat(chi?.platform_fee),
					balance_before: RavenNumberFormat(chi?.b_before) || 'NIL',
					balance_after: RavenNumberFormat(chi?.b_after) || 'NIL',
					stamp_duty: RavenNumberFormat(chi?.stamp_duty) || 'NIL',
					serial:
						chi?.serial ||
						formatMetaData(chi?.pdon_meta)?.terminal?.serial ||
						'NIL',
					terminal_label:
						formatMetaData(chi?.pdon_meta)?.terminal?.tid_label || 'NIL',
					tranx_reference:
						chi?.type === 'transfer_in'
							? formatMetaData(chi?.meta_data)?.session_id
							: chi?.rrn || 'NIL',
					transaction_details:
						chi?.type === 'transfer_in'
							? `Account Name: ${
									formatMetaData(chi?.meta_data)?.source?.first_name || ''
							  } ${
									formatMetaData(chi?.meta_data)?.source?.last_name || ''
							  }, Account Number: ${
									formatMetaData(chi?.meta_data)?.source?.account_number
							  } ,  Bank: ${formatMetaData(chi?.meta_data)?.source?.bank} `
							: chi?.type === 'pdon_card' && !chi?.meta_data
							? maskCardNumber(formatMetaData(chi?.pdon_meta)?.card?.card_pan)
							: `${chi?.stan}`,
					// provider:
					// chi?.provider  || "NIL",
					created_at: chi?.created_at
						? formatDateHelper(chi?.created_at)?.replace('—', '•')
						: 'NIL',
					status: String(chi?.status) === '00' ? `Success` : `Failed`,
				};
			});

			downloadCsvFile(csvHeader, newData, 'transaction_data.csv');
		}
	};

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'transactions'
		)?.includes('view_transactions')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="All Transaction made on the bankbox"
					pageTitle="BankBox Transaction"
					topRightContent={<></>}
				>
					{!noDataFound && (
						<div className="global-search-pagination-filter-box">
							<SmartFilter
								searchTitle="Search by stan, RRN and email"
								defaultFilterBy="status"
								onSearchChange={debounce((e) => {
									// console.log(e);
									dispatch(
										bankboxAPI.getAllTransactions({
											per_page: 20,
											page: currentPage,
											search: e,
										})
									);
								}, 500)}
								filters={[
									{
										label: 'Status',
										filterBy: 'status',
										options: [
											{
												label: 'Successful',
												value: 'success',
											},
											{
												label: 'Failed',
												value: 'failed',
											},
											{
												label: 'All',
												value: 'reset',
											},
										],
									},
								]}
								filtersTwo={[
									{
										label: 'Type',
										filterBy: 'type',
										options: [
											{
												label: 'Card',
												value: 'pdon_card',
											},
											{
												label: 'Transfer In',
												value: 'transfer_in',
											},
											// {
											// 	label: 'Transfer Out',
											// 	value: 'transfer_out',
											// },
											{
												label: 'All',
												value: 'reset',
											},
										],
									},
								]}
								onExport={() => setShowExport(true)}
								page="bankbox_transactions"
							/>
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={all_bankbox_trx?.perPage ?? 0}
								totalItems={all_bankbox_trx?.total ?? 0}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>
					)}

					<div
						// onClick={() => {
						// 	console.log(trx);
						// }}
						className="content__main"
					>
						<div className="main__wrap">
							{loading ||
							searchLoading ||
							all_bankbox_trx?.data?.length === 0 ? (
								<EmptyScreen
									loading={loading || searchLoading}
									loadingText={
										searchLoading ? 'Searching...' : 'Loading Transactions...'
									}
									title="No Transaction Found"
									subTitle="You don't have any record yet,your new bankbox transactions will be displayed here."
								/>
							) : (
								<>
									<div
										onClick={() => {
											// console.log(trx);
										}}
										className="transaction__table non-mobile"
									>
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'Merchant',
												'Type',
												'Amount',
												'Details',
												'Status',
												'Transaction Date',
											]}
										>
											{all_bankbox_trx?.data?.map((chi: any, idx: number) => {
												const meta = JSON.parse(chi.meta_data);
												const pdon = JSON.parse(chi.pdon_meta);

												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															onRowClick={() => {
																onView({
																	show: true,
																	content: {
																		...chi,
																		meta_data: meta,
																		pdon_meta: pdon,
																	},
																});
																// console.log(pdon);
																// console.log(meta);
																// console.log(chi);
															}}
															className="table__main"
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '10px' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	<div className="tooltip-hover-wrap">
																		{/* {formatStatus(meta?.response_code)} */}
																		<BadgeComponent
																			text={
																				chi?.status === '00'
																					? 'success'
																					: 'failed'
																			}
																			className={
																				chi?.status === '00'
																					? 'success'
																					: 'failed'
																			}
																		/>
																		{formatMetaData(chi?.pdon_meta)?.card
																			?.response_meaning && (
																			<RavenToolTip
																				color="deep-green-light"
																				text={
																					formatMetaData(chi?.pdon_meta)?.card
																						?.response_meaning
																				}
																				position={`left`}
																			/>
																		)}
																	</div>
																)
															}
															six={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	// formatDateTime(chi?.created_at)
																	<DateFragmentBox date={chi?.created_at} />
																)
															}
															two={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	convertToHumanReadable(chi?.type)?.replace(
																		'Pdon',
																		''
																	)
																)
															}
															one={
																<div className="account_name--transfer">
																	{loading ? (
																		<Skeleton circle width={35} height={35} />
																	) : (
																		<span
																			className="account_name__span"
																			style={{
																				backgroundColor:
																					generateRandomColor('#e9f5ff'),
																			}}
																		>
																			<p>
																				{returnInitial(
																					chi?.business_email || '--'
																				)}
																			</p>
																		</span>
																	)}

																	{loading ? (
																		<Skeleton width={230} height={20} />
																	) : (
																		<div className="bankbox-trx-tbl-name">
																			<p className="text">
																				{' '}
																				{chi?.business_email || '--'}
																			</p>
																			{chi.type === 'transfer_in' ? (
																				<p>
																					{meta?.account_number} •{' '}
																					{pdon?.terminal?.tid_label}
																				</p>
																			) : (
																				<p>
																					{chi?.serial} •{' '}
																					{
																						pdon?.terminal_profile
																							?.poseidon_business_name
																					}
																				</p>
																			)}
																		</div>
																	)}
																	{loading ? (
																		<Skeleton circle width={35} height={35} />
																	) : (
																		<>
																			<div
																				onClick={(e) => {
																					e.stopPropagation();
																					localStorage?.setItem(
																						'merchant-email-user-preview',
																						chi?.business_email
																					);
																					navigate(
																						'/dashboard-bankbox/user-single'
																					);
																				}}
																				className="go-to-merchant tooltip-hover-wrap"
																			>
																				<RavenToolTip
																					color="deep-green-light"
																					position={`right`}
																					text={`View merchant ${trimLongString(
																						chi?.business_email,
																						20
																					)}`}
																				/>
																				<figure className="img-box grey-bg">
																					{shareIcon}
																				</figure>
																			</div>
																		</>
																	)}
																</div>
															}
															four={
																loading ? (
																	<Skeleton width={80} height={20} />
																) : (
																	<div className="type-wrap">
																		<TwoDetailView
																			title={
																				chi?.type === 'transfer_in'
																					? `${meta?.source?.first_name} ${meta?.source?.last_name}`
																					: formatMetaData(chi?.pdon_meta)
																							?.card_meta?.card_pan || '---'
																			}
																			text_one={
																				chi?.type === 'transfer_in'
																					? `${trimLongString(
																							meta?.source?.bank,
																							22
																					  )} • ${
																							meta?.source?.account_number
																					  }`
																					: `RRN: ${chi?.rrn}`
																			}
																		/>
																	</div>
																)
															}
															three={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	symbol('ngn') +
																	formatNumWithComma(chi?.amount, 'ngn')
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{all_bankbox_trx?.data?.map(
											(chi: any, idx: React.Key | null | undefined) => {
												const meta = JSON.parse(chi.meta_data);
												const pdon = JSON.parse(chi.pdon_meta);

												// const { first_name, last_name, bank } = meta_data;

												// logger.log(meta_data);
												return (
													<MobileTableCard
														key={idx}
														title={
															pdon?.terminal_profile?.poseidon_business_name ??
															'--'
														}
														text={formatDateTime(chi.created_at)}
														amount={
															symbol('ngn') +
															formatNumWithComma(chi.amount, 'ngn')
														}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={chi.direction}
														showImg={true}
														loading={loading}
														onRowClick={() =>
															onView({
																show: true,
																content: chi,
															})
														}
														textTwo={''}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>

			<TransactionModal
				status={view?.content?.status === '00' ? 'success' : 'failed'}
				onClose={() => {
					// console.log(view?.content);

					onView({
						show: false,
						content: {
							type: '',
							bank: '',
							description: '',
							amount: '',
							date: '',
							session_id: '',
							account_number: '',
							email: '',
							created_at: function (created_at: any): string {
								throw new Error('Function not implemented.');
							},
							updated_at: function (updated_at: any): string {
								throw new Error('Function not implemented.');
							},
						},
						on: false,
					});
				}}
				visible={view.show}
				direction={view?.content?.direction}
				title={'Transactions Details'}
				amount={view?.content?.amount as unknown as number}
				onClick={() => {}}
				meta={JSON.stringify({
					...view?.content?.meta_data,
					...view?.content?.pdon_meta,
				})}
				content={
					view?.content?.type === 'transfer_in'
						? [
								{
									label: 'Narration',
									value: view?.content?.meta_data?.source?.narration || '--',
								},
								{
									label: 'Amount',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.amount, 'ngn'),
								},
								// {
								// 	label: 'Amount',
								// 	value:
								// 		symbol('ngn') +
								// 		formatNumWithComma(view?.content?.amount, 'ngn'),
								// },
								{
									label: 'Fee',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.fee, 'ngn'),
								},
								{
									label: 'Bank',
									value: view?.content?.meta_data?.source?.bank || '--',
								},
								{
									label: 'Account Name',
									value:
										view?.content?.meta_data?.source?.first_name +
											' ' +
											view?.content?.meta_data?.source?.last_name ||
										'--' ||
										'--',
								},
								{
									label: 'Account Number',
									value:
										view?.content?.meta_data?.source?.account_number || '--',
									copy: true,
									trim: true,
								},
								// {
								// 	label: 'Merchant Reference',
								// 	value: view?.content?.reference || '--',
								// 	copy: true,
								// },
								// {
								// 	label: 'Card Pan',
								// 	value:
								// 		view?.content?.pdon_meta?.card_meta?.card_pan ||
								// 		maskCardNumber(view?.content?.pdon_meta?.card?.card_pan) ||
								// 		'--',
								// 	copy: true,
								// 	trim: true,
								// },
								{
									label: 'Terminal ID',
									value: view?.content?.t_id || '--',
									copy: true,
									trim: true,
								},

								{
									label: 'Session ID',
									value: view?.content?.meta_data?.session_id || '--',
									copy: true,
									trim: true,
								},

								{
									label: 'Reason',
									value: view?.content?.pdon_meta?.card?.response_meaning
										? view?.content?.pdon_meta?.card?.response_meaning
										: '--',
									// copy: true,
									dontSHow: view?.content?.pdon_meta?.card?.response_meaning
										? false
										: true,
								},
								{
									label: 'Created At',
									value: formatDateTime(
										view?.content?.created_at as unknown as string
									),
								},
						  ]
						: [
								// {
								// 	label: 'Serial',
								// 	value: view?.content?.serial || '--',
								// },
								{
									label: 'Amount',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.amount, 'ngn'),
								},
								{
									label: 'Fee',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.fee, 'ngn'),
								},
								// {
								// 	label: 'Issuing App',
								// 	value:
								// 		view?.content?.pdon_meta?.terminal_profile?.issuing_app ||
								// 		'--',
								// },
								{
									label: 'Business Email',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_owner_email || '--',
								},
								{
									label: 'Business Name',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_business_name || '--',
								},
								{
									label: 'Serial Number',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_serial_number || '--',
									copy: true,
									trim: true,
									// dontSHow: true,
								},
								{
									label: 'Terminal ID',
									value: view?.content?.t_id || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Card Pan',
									value:
										view?.content?.pdon_meta?.card_meta?.card_pan ||
										maskCardNumber(view?.content?.pdon_meta?.card?.card_pan) ||
										'--',
									copy: true,
									trim: true,
								},
								{
									label: 'RRN',
									value: view?.content?.rrn || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Stan',
									value: view?.content?.stan || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Merchant Reference',
									value: view?.content?.reference || '--',
									copy: true,
								},

								{
									label: 'Reason',
									value: view?.content?.pdon_meta?.card?.response_meaning
										? view?.content?.pdon_meta?.card?.response_meaning
										: '--',
									// copy: true,
									dontSHow: view?.content?.pdon_meta?.card?.response_meaning
										? false
										: true,
								},
								{
									label: 'BankBox Label',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_label || '--',
								},
								{
									label: 'Created At',
									value: formatDateTime(
										view?.content?.created_at as unknown as string
									),
								},
						  ]
				}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_transactions"
				onFinishExport={(param) => {
					formatExportData(param);
				}}
			/>
		</>
	);
};

export default BankBoxTransactions;
